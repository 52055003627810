import React, { useRef, useEffect } from 'react';
import MicButton from './MicButton';

const ChatInput = ({ messageText, setMessageText }) => {
  const textareaRef = useRef(null);

  function adjustTextareaHeight() {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = `auto`;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  useEffect(() => {
    adjustTextareaHeight();
  }, [messageText]);

  return (
    <>
      <textarea
        rows={1}
        ref={textareaRef}
        id="mobile-text-area"
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
        className="chat-textbox"
        placeholder="Type question/query"
      />
      <MicButton onSpeech={setMessageText} />
    </>
  );
};

export default ChatInput;
