import axios from 'axios';
import { getAccessToken } from '../services/token';
import { getServerLocation } from '../utils/serverLocation';

const server = getServerLocation();

const nudgeApi = axios.create({
  baseURL: server,
});

nudgeApi.interceptors.request.use(async function (config) {
  const accessToken = await getAccessToken();
  config.headers.Authorization = 'Bearer ' + accessToken;

  return config;
});

export default nudgeApi;
