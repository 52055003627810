import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import nudgeApi from '../../apis/nudgeApi';
import { PageTitle } from '../pageTitle';
import NutritionAutoSuggest from './NutritionAutoSuggest';
import { NutritionLabel } from '../common';
import NutritionTable from './NutrientTable';
import {
  getNutritionTotal,
  createFoodReferenceObject,
  getCustomNutrition,
} from './ingredientSearchHelper';
import { UNITS_OF_WEIGHT } from '../../utils/constants';

/**
 * Ingredient search page, contains components for displaying nutrient information
 */
function IngredientSearch({
  displayTitle = true,
  displayLabel = true,
  setIngredientData,
  displayStatus = false,
  ingredientData,
}) {
  const defaultSearchState = { branded: [], common: [] };
  const [inputText, setInputText] = useState('');

  /**
   * Array of objects with the following properties
   * @property servingQuantity: Number of servings
   * @property servingSize: The size of the serving
   * @property measurementType: Type of measurement
   * @property weight: the weight in grams
   * @property name: name of the food item
   * @property ndb_no: ndb_no of the food item
   * @property calories: calories of the food item
   * @property status: Either Active "A" or Deactivated "D"
   * }
   */
  const [foodQuantity, setFoodQuantity] = useState({});
  const [searchSuggestions, setSearchSuggestions] = useState(defaultSearchState);
  const [foodData, setFoodData] = useState([]);
  const [totalNutrition, setTotalNutrition] = useState({});
  // Total amount of servings for recipe
  const [totalServings, setTotalServings] = useState(1);
  // Amount of servings the nutrition label will provide information for
  const [servings, setServings] = useState(totalServings);
  // Ingredient data that is formatted to how it is stored in our database

  const getFoodData = async (foodId) => {
    const response = await nudgeApi.get(`/nutrition/search/item?key=${foodId}&type=3`);
    const food = get(response, 'data.data', {});
    return food;
  };

  // Adds Food Data to existing list
  const addFoodData = async (foodId, foodMetricQuantity) => {
    const food = await getFoodData(foodId);
    if (food) {
      // In case value is not defined
      if (!food.serving_weight_grams) {
        food.serving_weight_grams = foodMetricQuantity;
      }
      setFoodData([...foodData, food]);
    }
  };

  const servingRatio = servings / totalServings;

  // Used to search for suggestions and ensure API is not running every key press
  const debounceText = useCallback(
    debounce(async (text) => {
      const response = await nudgeApi.get(`/nutrition/search/instant?q=${text}&type=3`);
      const searchObject = get(response, 'data.data', defaultSearchState);
      setSearchSuggestions(searchObject);
    }, 500),
    [],
  );

  useEffect(() => {
    setSearchSuggestions(defaultSearchState);
    if (inputText !== '') {
      debounceText(inputText);
    }
  }, [inputText]);

  useEffect(() => {
    const result = { ...foodQuantity };
    foodData.forEach((foodEntry) => {
      if (!result[foodEntry.name]) {
        result[foodEntry.name] = {
          servingQuantity: foodEntry.serving_size_qty,
          servingSize: foodEntry.serving_metric_qty || 1,
          measurementType: UNITS_OF_WEIGHT.GRAM,
          status: 'A',
          name: foodEntry.name,
          food_id: foodEntry.id,
        };
      }
    });

    getNutritionTotal(foodQuantity, [], servingRatio);
    setFoodQuantity(result);
  }, [foodData]);

  const getAllIngredientData = async (allIngredients) => {
    console.log('All ingredients: ', allIngredients);
    const promises = allIngredients.ingredients.map((data) => addFoodData(data.id));
    const fetchedIngredients = await Promise.all(promises);
    setFoodData([...foodData, fetchedIngredients]);
  };

  useEffect(() => {
    if (ingredientData) {
      getAllIngredientData(ingredientData);
    }
  }, []);

  useEffect(() => {
    setTotalNutrition(getNutritionTotal(foodQuantity, foodData, servingRatio));
  }, [foodQuantity, totalServings, servings]);

  useEffect(() => {
    setServings(1);
  }, [totalServings]);

  useEffect(() => {
    if (setIngredientData) {
      /**
       * @property totalNutrition: This will be the total nutrition object
       * @property ingredients: array of strings with ingredient names
       * @property servings: number of servings total nutrition accomadates for
       * }
       */

      const ingredientResult = [];

      foodData.forEach((foodEntry) => {
        const foodReference = createFoodReferenceObject(foodEntry);
        const item = foodQuantity[foodEntry.food_name];
        if (item) {
          ingredientResult.push({
            name: item.name,
            unit: item.measurementType,
            status: item.status,
            source: 'NUTRITIONIX',
            qty: item.servingSize,
            calories: getCustomNutrition(foodReference.calorieInfo, item),
            weight: getCustomNutrition(foodReference.servingWeightInfo, item),
            food_id: item.food_id,
          });
        }
      });

      setIngredientData({
        totalNutrition,
        ingredients: ingredientResult,
        servings,
      });
    }
  }, [totalNutrition, foodQuantity]);

  return (
    <div className="pageContentWrapper">
      {displayTitle && <PageTitle title="Ingredient Search" />}
      <SearchInfoContainer displayLabel={displayLabel}>
        <NutritionAutoSuggest
          inputText={inputText}
          setInputText={setInputText}
          suggestions={searchSuggestions}
          addFoodData={addFoodData}
          className="ingredient-autosuggest"
        />
        <div className="ingredient-search-serving-container">
          <div className="total-serving-text">
            Number of servings the completed recipe will serve:
          </div>
          <input
            type="number"
            min={1}
            value={totalServings}
            onChange={(e) => {
              const input = parseInt(e.target.value) || 1;
              if (input === 0) {
                setTotalServings(1);
              } else {
                setTotalServings(input);
              }
            }}
          />
        </div>
        <div className="nutrition-info-summary">
          {displayLabel && (
            <NutritionLabel
              servingSize={totalNutrition.totalWeight}
              calories={totalNutrition.calories}
              caloriesFromFat={totalNutrition.caloriesFromFat}
              totalFat={Math.round(totalNutrition.totalFat * 10) / 10}
              saturatedFat={totalNutrition.saturatedFat}
              transFat={totalNutrition.transFat}
              polyUnsaturatedFat={totalNutrition.polyunsaturatedFat}
              monoUnsaturatedFat={totalNutrition.monounsaturatedFat}
              cholesterol={totalNutrition.cholesterol}
              sodium={totalNutrition.sodium}
              potassium={totalNutrition.potassium}
              totalCarbs={totalNutrition.totalCarbs}
              dietaryFibre={totalNutrition.dietaryFiber}
              sugar={totalNutrition.sugars}
              protein={totalNutrition.protein}
              vitaminA={totalNutrition.vitaminA}
              vitaminC={totalNutrition.vitaminC}
              vitaminD={totalNutrition.vitaminD}
              calcium={totalNutrition.calcium}
              iron={totalNutrition.iron}
            />
          )}
          <NutritionTable
            className="search-food-table"
            foodData={foodData}
            setFoodData={setFoodData}
            setFoodQuantity={setFoodQuantity}
            foodQuantity={foodQuantity}
            totalNutrition={totalNutrition}
            totalServings={totalServings}
            setTotalServings={setTotalServings}
            servings={servings}
            setServings={setServings}
            displayStatus={displayStatus}
          />
        </div>
      </SearchInfoContainer>
    </div>
  );
}

const SearchInfoContainer = styled.div`
  .nutrition-info-summary {
    display: flex;
  }

  .search-food-table {
    margin-left: ${(props) => (props.displayLabel ? '50px' : '0px')};
    @media (max-width: 1600px) {
      width: 100%;
    }
  }

  .ingredient-autosuggest {
    width: 40%;
  }

  .ingredient-search-serving-container {
    display: flex;
    margin: 15px 0;
    .total-serving-text {
      margin-right: 5px;
    }
  }
`;

IngredientSearch.propTypes = {
  displayTitle: PropTypes.bool,
  displayLabel: PropTypes.bool,
  // Hook setter if ingredient data is required outside this component
  setIngredientData: PropTypes.func,
  displayStatus: PropTypes.bool,
  ingredientData: PropTypes.object,
};

export default IngredientSearch;
