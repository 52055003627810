// Route list to assign breadcrumbs name
export const routeList = [
    { path: '/', breadcrumb: 'Nudge' },
    { path: '/dashboard', breadcrumb: 'Dashboard' },
    { path: '/staffs', breadcrumb: 'CMS Users' },
    { path: '/staffs/:id', breadcrumb: 'User' },
    { path: '/users/', breadcrumb: 'Customers' },

    { path: '/smartgoals', breadcrumb: 'Smart Goals' },
    { path: '/smartgoals/lookups', breadcrumb: 'Goal Lookup' },
    { path: '/users/', breadcrumb: 'Customers' },

    { path: '/smartgoals/lookup/new', breadcrumb: 'Add Lookup' },
    { path: '/smartgoals/lookups/:id', breadcrumb: 'Edit Lookup' },

    { path: '/exerciseLogger/', breadcrumb: 'Exercise Logger' },

    { path: '/exerciseLogger/exerciseByEquipmentList/', breadcrumb: 'Exercise By Equipment' },
    { path: '/exerciseLogger/exerciseByEquipmentList', breadcrumb: 'Edit Exercise By Equipment' },
    { path: '/exerciseLogger/exerciseByEquipmentAdd', breadcrumb: 'Add Exercise By Equipment' },

    { path: '/exerciseLogger/guidesList', breadcrumb: 'Guides' },
    { path: '/exerciseLogger/guidesList/:id', breadcrumb: 'Edit Guides' },
    { path: '/exerciseLogger/guidesAdd', breadcrumb: 'Add Guides' },

    { path: '/appContent', breadcrumb: 'App Content' },

    { path: '/appContent/mealLoggerList', breadcrumb: 'Meal Logger' },
    { path: '/appContent/mealLoggerList/', breadcrumb: 'Edit Meal Logger' },
    { path: '/appContent/mealLoggerAdd', breadcrumb: 'Add Meal Logger' },

    { path: '/exerciseLogger/tipsList', breadcrumb: 'Tips' },
    { path: '/exerciseLogger/tipsList/:id', breadcrumb: 'Edit Tips' },
    { path: '/exerciseLogger/tipsAdd', breadcrumb: 'Add Tips' },


    { path: '/habit', breadcrumb: 'Habit Analysis' },

    { path: '/eventHistory', breadcrumb: 'Event History' },
    { path: '/shoppingcart', breadcrumb: 'Shopping Cart' },
    { path: '/livecoach', breadcrumb: 'Live Coach' },

    { path: '/USDA', breadcrumb: 'USDA' },
    { path: '/eventHistory', breadcrumb: 'Elastic Search' },
    { path: '/shoppingcart', breadcrumb: 'Regular Search' },
    { path: '/usda/brands', breadcrumb: 'Brands' },
    { path: '/usda/categories', breadcrumb: 'Categories' },
    { path: '/usda/searchitems', breadcrumb: 'Combined Items' },
    { path: '/usda/fullsearch', breadcrumb: 'Elastic Search' },

    { path: '/shoppingcart/:restaurantid/items/', breadcrumb: 'Orders' },
    { path: '/restaurant', breadcrumb: 'Restaurant' },

    { path: '/mealLogger/restaurant', breadcrumb: 'Restaurant' },
    { path: '/mealLogger/restaurant/cuisine', breadcrumb: 'Cuisine' },
    { path: '/shoppingcart/:restaurantid/items/', breadcrumb: 'List' },
    { path: '/restaurant/list', breadcrumb: 'Restaurant' },

    { path: '/ingredient-search', breadcrumb: 'Ingredient Search' },
];
