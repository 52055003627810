import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';

const formatSuggestions = (suggestions) => {
  // Used to ensure their is no entry with null quantity values
  const common =
    suggestions && suggestions.common
      ? suggestions.common.filter(
          (entry) => entry.serving_weight_gram !== null && entry.metric_qty !== null,
        )
      : [];

  return [
    {
      title: 'COMMON FOODS',
      suggestions: common.slice(0, 4),
    },
    {
      title: 'BRANDED FOODS',
      suggestions: suggestions.branded ? suggestions.branded.slice(0, 4) : [],
    },
    {
      title: 'FOODS',
      suggestions: suggestions.foods ? suggestions.foods.slice(0, 4) : [],
    },
  ];
};

const renderSuggestion = (suggestion) => {
  return (
    <SuggestionContainer>
      <img src={suggestion.image_dir} className="suggestion-image" />
      <div>
        <p className="suggestion-food-title">{suggestion.name}</p>
        <p>{suggestion.brand_name}</p>
      </div>
    </SuggestionContainer>
  );
};

const renderSectionTitle = (section) => {
  return <strong>{section.title}</strong>;
};

const getSectionSuggestions = (section) => {
  return section.suggestions;
};

const NutritionAutoSuggest = ({ inputText, setInputText, suggestions, addFoodData, className }) => {
  const onSuggestionSelected = (suggestion) => {
    addFoodData(suggestion.id, suggestion.serving_metric_qty);
    setInputText('');
  };

  return (
    <Container className={className}>
      <InputGroup>
        <Autosuggest
          suggestions={formatSuggestions(suggestions)}
          renderSuggestion={renderSuggestion}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion}
          onSuggestionSelected={(_, { suggestion }) => onSuggestionSelected(suggestion)}
          inputProps={{
            value: inputText,
            onChange: (e) => setInputText(e.target.value),
            placeholder: 'Search for ingredients to add to the table',
          }}
          multiSection={true}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
        />
      </InputGroup>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  body {
    font-family: Helvetica, sans-serif;
  }

  .react-autosuggest__container {
    position: relative;
    width: 100%;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 10px 20px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 30px;
    left: 20px;
    width: 180px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
  }

  .react-autosuggest__section-container--first {
    border-top: 0;
  }

  .react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
  }
`;

NutritionAutoSuggest.propTypes = {
  inputText: PropTypes.string.isRequired,
  setInputText: PropTypes.func.isRequired,
  addFoodData: PropTypes.func.isRequired,
  suggestions: PropTypes.object,
};

const SuggestionContainer = styled.div`
  display: flex;
  padding-bottom: 15px;

  .suggestion-food-title {
    font-weight: 600;
  }

  .suggestion-image {
    object-fit: cover;
    width: 40px;
    height: 40px;
  }
  border-bottom: 1px dashed #777;

  p {
    padding: 0px;
    margin: 0px;
  }
`;

export default NutritionAutoSuggest;
