import * as React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseRounded from '@material-ui/icons/CloseRounded';

// Uses DialogProps
const CustomModal = ({
  children,
  open,
  onClose,
  title,
  headerColor,
  maxWidth = false,
  ...props
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth={maxWidth}
    {...props}
  >
    <StyledModalTitle headerColor={headerColor}>
      <div className="custom-modal-button-container">
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="custom-modal-close"
        >
          <CloseRounded className="tree-close-icon" />
        </IconButton>
      </div>
      <h4>{title}</h4>
    </StyledModalTitle>
    {children}
  </Dialog>
);

const StyledModalTitle = styled.div`
  background-color: ${(props) => (props.headerColor ? props.headerColor : '#1E96DC')};
  color: white;
  min-height: 100px;
  min-width: 300px;
  padding: 0 0 20px 15px;

  h4 {
    padding-right: 50px;
  }

  .custom-modal-button-container{
    display: flex;
    justify-content: flex-end;
  }

  .tree-close-icon{
    color: white;
    stroke: white;
  }
`;

export default CustomModal;
