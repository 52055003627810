import Button from "@material-ui/core/Button";
import history from "../../history";
import { useHistory } from "react-router-dom";

function LinkButton({ size = "small", icon = "", label = "edit", url, params = "" }) {
  let history = useHistory();
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      size={size}
      onClick={() => history.push(url, params)}
    >
      {icon && <i class={icon} style={{ paddingRight: "8px" }}></i>}
      {label}
    </Button>
  );
}

export default LinkButton;
