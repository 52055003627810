import nudgeApi from '../../apis/nudgeApi';

export const fetchSmartgoals = () => async (dispatch) => {
  try {
    const ret = await nudgeApi.get('/app/smartgoals');

    dispatch({ type: 'FETCH_SMARTGOALS', payload: ret.data });
  } catch (error) {
    console.log('smartgoals error', error);
  }
};

export const fetchSmartgoalsLookup = () => async (dispatch) => {
  try {
    const ret = await nudgeApi.get('/app/smartgoalsLookup');
    dispatch({ type: 'FETCH_SMARTGOALS_LOOKUP', payload: ret.data });
  } catch (error) {
    console.log('smartgoalsLookup error', error);
  }
};

export const fetchSmartgoalsLookupMapping = () => async (dispatch) => {
  try {
    const ret = await nudgeApi.get('/app/smartgoalsLookupMapping');
    dispatch({ type: 'FETCH_SMARTGOALS_LOOKUP_MAPPING', payload: ret.data });
  } catch (error) {
    console.log('smartgoalsLookupMapping error', error);
  }
};
