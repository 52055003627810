import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import nudgeApi from '../../apis/nudgeApi';
import { SocketChatbox } from '../common/ChatBox';

function MobileChatPage() {
  const [userInfo, setUserInfo] = useState({});
  const splitPathName = window.location.pathname.split('/');
  const queryParameters = new URLSearchParams(window.location.search);
  const urlInputValue = queryParameters.get('hideInput');
  const hideInput = urlInputValue === 'true';
  const token = splitPathName[2];

  useEffect(() => {
    const getMobileInfo = async () => {
      const response = await nudgeApi.get(`/messaging/chat/${token}/users`);
      if (response.data && response.data.data) {
        setUserInfo(response?.data?.data?.user || {});
      }
    };

    getMobileInfo();
  }, [token]);

  return (
    <MobilePageContainer>
      {Object.keys(userInfo).length > 0 && (
        <SocketChatbox
          currentUser={userInfo?.user_id}
          userToSendTo="B"
          isMobile
          token={token}
          displayMobileSendMessage={!hideInput}
          autoScrollBottom
        />
      )}
    </MobilePageContainer>
  );
}

const MobilePageContainer = styled.div`
  height: 100vh;
`;

export default MobileChatPage;
