import { NUTRITIONIX_NUTRIENT_ID, UNITS_OF_WEIGHT } from '../../utils/constants';
import { roundFloat } from '../../utils/numberUtils';

/**
 * Converts units of measurements to grams
 * @param {number} metricUnits - Metric units
 * @param {string} measurementType - Unit of measurement to convert from
 * @returns
 */
export const convertToGrams = (units = 0, measurementType) => {
  let result;
  const gramsInTeaspoon = 2.53;

  switch (measurementType) {
    case UNITS_OF_WEIGHT.KILOGRAM:
      result = units * 1000;
      break;
    case UNITS_OF_WEIGHT.OUNCE:
      result = units * 28.3495;
      break;
    case UNITS_OF_WEIGHT.POUND:
      result = units * 453.592;
      break;
    case UNITS_OF_WEIGHT.TEASPOON:
      result = units * gramsInTeaspoon;
      break;
    case UNITS_OF_WEIGHT.THREE_QUARTERS_TEASPOON:
      result = (units * gramsInTeaspoon * 3) / 4;
      break;
    case UNITS_OF_WEIGHT.HALF_TEASPOON:
      result = (units * gramsInTeaspoon) / 4;
      break;
    case UNITS_OF_WEIGHT.THIRD_TEASPOON:
      result = (units * gramsInTeaspoon) / 3;
      break;
    case UNITS_OF_WEIGHT.QUARTER_TEASPOON:
      result = (units * gramsInTeaspoon) / 4;
      break;
    case UNITS_OF_WEIGHT.EIGTH_TEASPOON:
      result = units * (gramsInTeaspoon / 8);
      break;
    case UNITS_OF_WEIGHT.TABLESPOON:
      result = units * 12.5;
      break;
    case UNITS_OF_WEIGHT.CUPS:
      result = units * 128;
      break;
    case UNITS_OF_WEIGHT.QUARTER_CUP:
      result = (units * 128) / 4;
      break;
    case UNITS_OF_WEIGHT.THIRD_CUP:
      result = (units * 128) / 3;
      break;
    case UNITS_OF_WEIGHT.HALF_CUP:
      result = (units * 128) / 2;
      break;
    default:
      result = units;
  }

  return result;
};

/**
 * Creates object with nutritionix food values which will be used for reference
 * @param {object} food - Nutritionix food object (formatted in the same style as the Nutritionix API)
 * @param {number} refValue - Nutritional food value
 * @returns {object} - The food reference object
 */
export const createFoodReference = (food, refValue) => {
  return {
    refWeight: food.serving_metric_qty,
    refQuantity: food.serving_size_qty,
    refValue,
  };
};

/**
 * Retrieves food data regarding a specific nutrient
 * @param {object} referenceFoodData - Food data obtained from nutritionix API, used as reference
 * @param {object} newFoodData - Custom food data, contains quanity/metric info
 * @returns {number} - New total value of a specific nutrient.
 */
export const getCustomNutrition = (referenceFoodData, newFoodData) => {
  const { refWeight, refQuantity, refValue } = referenceFoodData;
  const { servingQuantity, measurementType, servingSize } = newFoodData;
  const servingSizeGrams = convertToGrams(servingSize, measurementType);
  const referenceWeightPerServing = refWeight / refQuantity;
  // Gives weight in grams based on the measurement Type
  const convertedWeightPerServing = servingSizeGrams / referenceWeightPerServing;
  // Nutritional value per serving based on data returned by Nutritionix API
  const referenceNutritionPerServing = refValue / refQuantity;
  // Nutrition to add based on custom serving size
  const convertedNutrition = convertedWeightPerServing * referenceNutritionPerServing;

  return Math.round(convertedNutrition * 10) / 10;
};

/**
 * Searches an array of nutrient values given a specific nutrient id
 * @param {number} nutrientId - Id of the nutrient to find
 * @param {[{ attr_id: number, value: number }]} nutrientArray - array of nutrient values
 * @returns {number} - Returns nutrient value
 */
export const getNutrientValue = (nutrientId, nutrientArray = []) => {
  const result = nutrientArray.find((nutrient) => nutrientId === nutrient.attr_id);

  return result ? result.value : 0;
};

/**
 * Creates object with food reference information for a variety of nutrients
 * @param {object} food - Nutritionix food object (formatted in the same style as the Nutritionix API)
 * @returns {object} - Food reference information of all the relevant nutrients
 */
export const createFoodReferenceObject = (food) => {
  const transFat = getNutrientValue(NUTRITIONIX_NUTRIENT_ID.TRANS_FAT, food.full_nutrients);
  const polyunsaturatedFat = getNutrientValue(
    NUTRITIONIX_NUTRIENT_ID.POLYUNSATURATED_FAT,
    food.full_nutrients,
  );
  const monounsaturatedFat = getNutrientValue(
    NUTRITIONIX_NUTRIENT_ID.MONOUNSATURATED_FAT,
    food.full_nutrients,
  );
  const vitaminA = getNutrientValue(NUTRITIONIX_NUTRIENT_ID.VITAMIN_A, food.full_nutrients);
  const vitaminC = getNutrientValue(NUTRITIONIX_NUTRIENT_ID.VITAMIN_C, food.full_nutrients);
  const vitaminD = getNutrientValue(NUTRITIONIX_NUTRIENT_ID.VITAMIN_D, food.full_nutrients);
  const calcium = getNutrientValue(NUTRITIONIX_NUTRIENT_ID.CALCIUM, food.full_nutrients);
  const iron = getNutrientValue(NUTRITIONIX_NUTRIENT_ID.IRON, food.full_nutrients);

  return {
    servingWeightInfo: createFoodReference(food, food.serving_metric_qty),
    calorieInfo: createFoodReference(food, food.nf_calories),
    totalFatInfo: createFoodReference(food, food.nf_total_fat),
    saturatedFatInfo: createFoodReference(food, food.nf_saturated_fat),
    cholesterolInfo: createFoodReference(food, food.nf_cholesterol),
    sodiumInfo: createFoodReference(food, food.nf_sodium),
    proteinInfo: createFoodReference(food, food.nf_protein),
    carbInfo: createFoodReference(food, food.nf_total_carbohydrate),
    sugarInfo: createFoodReference(food, food.nf_sugars),
    potassiumInfo: createFoodReference(food, food.nf_potassium),
    dietaryFibreInfo: createFoodReference(food, food.nf_dietary_fiber),
    transFatInfo: createFoodReference(food, food.nf_trans_fat),
    polyFatInfo: createFoodReference(food, food.polyFatInfo),
    monoFatInfo: createFoodReference(food, food.monoFatInfo),
    vitaminAInfo: createFoodReference(food, food.nf_vitamin_a),
    vitaminCInfo: createFoodReference(food, food.nf_vitamin_c),
    vitaminDInfo: createFoodReference(food, food.nf_vitamin_d),
    calciumInfo: createFoodReference(food, food.nf_calcium),
    ironInfo: createFoodReference(food, food.nf_iron),
  };
};

/**
 * Gets total nutritional information given an array of foodData objects
 * @param {[object]} quantity - Object that contains custom quantity/serving infromation
 * @param {[object]} foodData - Array of food objects formatted in the style of the Nutritionix API
 * @param {number} servingRatio - Represents the serving number the recipe will give divided by the nutrition total serving number
 * @returns {object} - Sum of all the food data's nutritional value
 */
export const getNutritionTotal = (quantity, foodData = [], servingRatio = 1) => {
  const foodNutrition = {
    servingSize: 0,
    calories: 0.0,
    caloriesFromFat: 0,
    totalFat: 0,
    transFat: 0,
    cholesterol: 0,
    sodium: 0,
    totalCarbs: 0,
    dietaryFiber: 0,
    sugars: 0,
    vitaminA: 0,
    vitaminC: 0,
    vitaminD: 0,
    calcium: 0,
    iron: 0,
    protein: 0,
    saturatedFat: 0,
    polyunsaturatedFat: 0,
    monounsaturatedFat: 0,
    potassium: 0,
    totalWeight: 0,
  };
  foodData.forEach((foodEntry) => {
    const foodReference = createFoodReferenceObject(foodEntry);

    const newTotalWeight =
      servingRatio * getCustomNutrition(foodReference.servingWeightInfo, quantity[foodEntry.name]);
    foodNutrition.totalWeight = roundFloat(foodNutrition.totalWeight + newTotalWeight, 1);

    const newTotalCalories =
      servingRatio * getCustomNutrition(foodReference.calorieInfo, quantity[foodEntry.name]);
    foodNutrition.calories = roundFloat(foodNutrition.calories + newTotalCalories, 1);

    const newTotalFat =
      servingRatio * getCustomNutrition(foodReference.totalFatInfo, quantity[foodEntry.name]);
    foodNutrition.totalFat = roundFloat(foodNutrition.totalFat + newTotalFat, 1);

    foodNutrition.caloriesFromFat = Math.round(foodNutrition.totalFat * 9 * 100) / 100;

    const newDietaryFibre =
      servingRatio * getCustomNutrition(foodReference.dietaryFibreInfo, quantity[foodEntry.name]);
    foodNutrition.dietaryFiber = roundFloat(foodNutrition.dietaryFiber + newDietaryFibre, 1);

    const newTotalProtein =
      servingRatio * getCustomNutrition(foodReference.proteinInfo, quantity[foodEntry.name]);
    foodNutrition.protein = roundFloat(foodNutrition.protein + newTotalProtein);

    const newSaturatedFat =
      servingRatio * getCustomNutrition(foodReference.saturatedFatInfo, quantity[foodEntry.name]);
    foodNutrition.saturatedFat = roundFloat(foodNutrition.saturatedFat + newSaturatedFat, 1);

    const newTotalSodium =
      servingRatio * getCustomNutrition(foodReference.sodiumInfo, quantity[foodEntry.name]);
    foodNutrition.sodium = roundFloat(foodNutrition.sodium + newTotalSodium, 1);

    const newTotalSugars =
      servingRatio * getCustomNutrition(foodReference.sugarInfo, quantity[foodEntry.name]);
    foodNutrition.sugars = roundFloat(foodNutrition.sugars + newTotalSugars, 1);

    const newTotalCarbs =
      servingRatio * getCustomNutrition(foodReference.carbInfo, quantity[foodEntry.name]);
    foodNutrition.totalCarbs = roundFloat(foodNutrition.totalCarbs + newTotalCarbs, 1);

    const totalCholesterol =
      servingRatio * getCustomNutrition(foodReference.cholesterolInfo, quantity[foodEntry.name]);
    foodNutrition.cholesterol = roundFloat(foodNutrition.cholesterol + totalCholesterol, 1);

    const totalPotassium =
      servingRatio * getCustomNutrition(foodReference.potassiumInfo, quantity[foodEntry.name]);
    foodNutrition.potassium = roundFloat(foodNutrition.potassium + totalPotassium, 1);

    const totalIron =
      servingRatio * getCustomNutrition(foodReference.ironInfo, quantity[foodEntry.name]);
    foodNutrition.iron = roundFloat(foodNutrition.iron + totalIron, 1);

    const totalCalcium =
      servingRatio * getCustomNutrition(foodReference.calciumInfo, quantity[foodEntry.name]);
    foodNutrition.calcium = roundFloat(foodNutrition.calcium + totalCalcium, 1);

    const totalVitaminA =
      servingRatio * getCustomNutrition(foodReference.vitaminAInfo, quantity[foodEntry.name]);
    foodNutrition.vitaminA = roundFloat(foodNutrition.vitaminA + totalVitaminA, 1);

    const totalVitaminC =
      servingRatio * getCustomNutrition(foodReference.vitaminCInfo, quantity[foodEntry.name]);
    foodNutrition.vitaminC = roundFloat(foodNutrition.vitaminC + totalVitaminC, 1);

    const totalVitaminD =
      servingRatio * getCustomNutrition(foodReference.vitaminDInfo, quantity[foodEntry.name]);
    foodNutrition.vitaminD = roundFloat(foodNutrition.vitaminD + totalVitaminD, 1);

    const totalMonounsaturatedFat =
      servingRatio * getCustomNutrition(foodReference.monoFatInfo, quantity[foodEntry.name]);
    foodNutrition.monounsaturatedFat = roundFloat(
      foodNutrition.monounsaturatedFat + totalMonounsaturatedFat,
      1,
    );

    const totalPolyunsaturatedFat =
      servingRatio * getCustomNutrition(foodReference.polyFatInfo, quantity[foodEntry.name]);
    foodNutrition.polyunsaturatedFat = roundFloat(
      foodNutrition.polyunsaturatedFat + totalPolyunsaturatedFat,
      1,
    );

    const totalTransfat =
      servingRatio * getCustomNutrition(foodReference.transFatInfo, quantity[foodEntry.name]);
    foodNutrition.transFat = roundFloat(foodNutrition.transFat + totalTransfat, 1);
  });

  return foodNutrition;
};
