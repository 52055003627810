import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { v4 as uuidv4 } from "uuid";

function MenuAddonServingOptions({ servingOptions, setServingOptions }) {
  function addServing() {
    setServingOptions((s) => [
      ...s,
      { id: uuidv4(), serving_qty: "", serving_unit: "", selected: false },
    ]);
  }
  function updateServing(id, name, value) {
    setServingOptions((s) =>
      s.map((opt) =>
        opt.id === id
          ? { ...opt, [name]: value }
          : name === "selected"
          ? { ...opt, selected: false }
          : opt
      )
    );
  }
  function deleteServing(id) {
    setServingOptions((s) => s.filter((opt) => opt.id !== id));
  }

  function checkDefaut() {
    const selectedItem = servingOptions.find((s) => s.selected === true);
    if (selectedItem) return selectedItem.id;
    else return null;
  }

  return (
    <div>
      <div style={styles.title}>Serving Options</div>
      <div style={styles.tableHeader}>
        <div style={{ width: "60px" }} />
        <div style={{ flex: "1", padding: "0px 0px" }}>Serving Quantity</div>
        <div style={{ flex: "1", padding: "0px 0px" }}>Serving Unit</div>
      </div>
      <RadioGroup
        name="defaultServing"
        value={checkDefaut()}
        onChange={(e) => updateServing(e.target.value, "selected", true)}
      >
        {servingOptions.map((opt) => (
          <div key={opt.id} style={styles.optionContainer}>
            <div>
              <FormControlLabel
                value={opt.id}
                control={<Radio />}
                style={{ margin: "0px" }}
              />
            </div>
            <div style={styles.row}>
              <TextField
                variant="outlined"
                name="serving_qty"
                size="small"
                padding="5px 10px"
                value={opt.serving_qty}
                onChange={(e) =>
                  updateServing(opt.id, "serving_qty", e.target.value)
                }
                style={styles.textfield}
              />
              <TextField
                variant="outlined"
                name="serving_unit"
                size="small"
                padding="5px 10px"
                value={opt.serving_unit}
                onChange={(e) =>
                  updateServing(opt.id, "serving_unit", e.target.value)
                }
                style={styles.textfield}
              />
              <div
                className="activeLinkRed"
                style={{ paddingLeft: "10px" }}
                onClick={() => deleteServing(opt.id)}
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        ))}
      </RadioGroup>

      <div
        className="activeLink"
        style={{ padding: "15px 20px" }}
        onClick={() => addServing()}
      >
        <i className="fas fa-plus" style={{ paddingRight: "10px" }}></i> Add
        more serving options
      </div>
    </div>
  );
}

const styles = {
  title: {
    padding: "5px 10px",
    borderBottom: "1px solid #ddd",
    margin: "10px 0px",
  },
  tableHeader: { display: "flex", flexDirection: "row", marginBottom: "5px" },
  optionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 0px 0px 5px",
  },
  row: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textfield: {
    flex: "1",
    margin: "5px 10px",
    backgroundColor: "white",
  },
};

export default MenuAddonServingOptions;
