const reducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_SMARTGOALS_LOOKUP":
      return [...action.payload];
    default:
      return state;
  }
};

export default reducer;
