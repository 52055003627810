import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { CustomModal } from '../CustomModal';

const DeleteModal = ({
  open,
  onClose,
  onDelete,
  textContent,
  title,
}) => (
  <CustomModal
    open={open}
    onClose={onClose}
    title={title}
    headerColor="#C30000"
  >
    <DeleteModalContainer>
      <p>{textContent}</p>
      <section className="delete-form-buttons">
        <Button
          variant="contained"
          className="delete-form-ok"
          onClick={async () => {
            await onDelete();
            onClose();
          }}
        >
          Ok
        </Button>
        <Button
          variant="outlined"
          className="delete-form-cancel"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </section>
    </DeleteModalContainer>
  </CustomModal>
);

const DeleteModalContainer = styled.div`
  width: 600px;
  margin: 20px;
  
  p {
    text-align: center;
    font-size: 18px;
  }


  Button {
    color: white;
  }

  .delete-form-ok {
    background-color: #1E96DC;
    margin-right: 10px;
  }

  .delete-form-cancel {
    color: #1E96DC;
    border-color: #1E96DC;
  }

  .delete-form-buttons{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

`;

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  textContent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DeleteModal;
