import PropTypes from 'prop-types';
import styled from "styled-components";
import { BackArrowIconButton, Breadcrumbs } from '../common';
/**
 * Title component that contains breadcrumbs and a back button for navigation
 */
const PageTitle = ({ title, links }) => (
    <Container>
        <BackArrowIconButton />
        <div className="breadcrumb-container" >
            <h4>{title}</h4>
            <Breadcrumbs links={links} />
        </div>
    </Container>
);

const Container = styled.div`
    display: flex;
    margin-bottom: 30px;

    h4 {
        font-weight: 400;
        margin-bottom: 2px;
    }

    .breadcrumb-container{
        margin-left: 5px;
    }
`;


PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string,
    })),
};

export default PageTitle;