import nudgeApi from '../../apis/nudgeApi';

export const fetchStoreTypes = () => async (dispatch) => {
  try {
    const ret = await nudgeApi.get('/app/storeTypes');
    dispatch({ type: 'FETCH_STORETYPES', payload: ret.data });
  } catch (error) {
    console.log('error', error);
  }
};
