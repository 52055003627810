import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Field, reduxForm } from 'redux-form';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from '@material-ui/core/Button';
import { FaEdit } from 'react-icons/fa';
import Rating from '@material-ui/lab/Rating';
import styled from 'styled-components';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import nudgeApi from '../../../apis/nudgeApi';
import FormInput from '../../form/FormInput';
import FormSelect from '../../form/FormSelect';
import uploadPhoto from '../../../utils/uploadPhoto';
import { INSIGHT_ARRAY } from '../../../utils/constants';
import LinkButton from '../../form/LinkButton';
import MenuAddon from './MenuAddon';
import { addonTypeLookup } from '../../../utils/menu';
import PageTitle from '../../pageTitle/PageTitle';
import { IngredientSearchModal } from '../../ingredientSearch';
import { ImageUploadComponent } from '../../common';

const nutritionFields = [
  { name: 'nf_calories', label: 'Calories' },
  { name: 'nf_calories_from_fat', label: 'Calories from fat' },
  { name: 'nf_cholesterol', label: 'Cholesterol (mg)' },
  { name: 'nf_dietary_fiber', label: 'Dietary Fiber (g)' },
  { name: 'nf_potassium', label: 'Potassium (mg)' },
  { name: 'nf_protein', label: 'Protein (g)' },
  { name: 'nf_vitamin_a', label: 'Vitamin A' },
  { name: 'nf_vitamin_c', label: 'Vitamin C' },
  { name: 'nf_vitamin_d', label: 'Vitamin D' },
  { name: 'nf_calcium', label: 'Calcium' },
  { name: 'nf_iron', label: 'Iron' },
  { name: 'nf_saturated_fat', label: 'Saturated_fat (g)' },
  { name: 'nf_sodium', label: 'Sodium (mg)' },
  { name: 'nf_sugars', label: 'Sugars (g)' },
  { name: 'nf_total_carbohydrate', label: 'Total Carbohydrate(g)' },
  { name: 'nf_total_fat', label: 'Total Fat (g)' },
  { name: 'nf_trans_fat', label: 'Trans Fat (g)' },
];

/**
 * Used to ensure and convert submitted values to the correct format
 * @param {Object} values -
 * @returns {Object} - The cloned values with the correct format
 */
const validateSubmissionValues = (values) => {
  const clonedValues = cloneDeep(values);

  nutritionFields.forEach((field) => {
    if (clonedValues.modification) {
      const modificationValue = parseFloat(clonedValues.modification[field.name]);
      clonedValues.modification[field.name] = isNaN(modificationValue) ? null : modificationValue;
    }
  });

  return clonedValues;
};

function MenuItem(props) {
  const { handleSubmit, change, state } = props;
  const [formdata, setFormdata] = useState({});
  const params = useParams();
  const [dietaryType, setDietaryType] = useState([]);
  const [allergens, setAllergens] = useState([]);

  const [restaurant, setRestaurant] = useState({});

  const [photo, setPhoto] = useState({});
  const [photoName, setPhotoName] = useState('');
  const [file, setFile] = useState('');
  const [photoFile, setPhotoFile] = useState('');

  const [addons, setAddons] = useState([]);
  const search = useLocation().search;
  const action = new URLSearchParams(search).get('action');

  const [addonAction, setAddonAction] = useState('');
  const [selectedAddon, setSelectedAddon] = useState({});
  const [show, setShow] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [displayModSearch, setDisplayModSearch] = useState(false);

  const [dishTypes, setDishTypes] = useState([]);

  const [rating, setRating] = useState(0);

  const history = useHistory();

  /**
   * @typedef IngredientData
   * @property {object} totalNutrition
   * @property {string[]} ingredients
   */
  const [ingredientData, setIngredientData] = useState();

  const servingFields = [
    { name: 'serving_size_qty', label: 'Serving Size' },
    { name: 'serving_size_unit', label: 'Serving Unit' },
    { name: 'serving_metric_qty', label: 'Serving Weight(grams)' },
  ];

  const applySearchChanges = () => {
    const { totalNutrition, servings } = ingredientData;

    // Used to indicate whether or not field is a modification
    const prefix = displaySearch ? '' : 'modification.';

    change(`${prefix}nf_calories`, get(totalNutrition, 'calories', 0));
    change(`${prefix}nf_calories_from_fat`, get(totalNutrition, 'caloriesFromFat', 0));
    change(`${prefix}nf_cholesterol`, get(totalNutrition, 'cholesterol', 0));
    change(`${prefix}nf_dietary_fiber`, get(totalNutrition, 'dietaryFiber', 0));
    change(`${prefix}nf_protein`, get(totalNutrition, 'protein', 0));
    change(`${prefix}nf_saturated_fat`, get(totalNutrition, 'saturatedFat', 0));
    change(`${prefix}nf_sodium`, get(totalNutrition, 'sodium', 0));
    change(`${prefix}nf_sugars`, get(totalNutrition, 'sugars', 0));
    change(`${prefix}nf_total_carbohydrate`, get(totalNutrition, 'totalCarbs', 0));
    change(`${prefix}nf_total_fat`, get(totalNutrition, 'totalFat', 0));
    change(`${prefix}nf_trans_fat`, get(totalNutrition, 'transFat', 0));
    change(`${prefix}nf_potassium`, get(totalNutrition, 'potassium', 0));

    if (!prefix) {
      change(`${prefix}serving_qty`, servings);
      change(`${prefix}serving_weight_grams`, get(totalNutrition, 'totalWeight', 0));
    }
  };

  const copyToModification = () => {
    change(`modification.nf_calories`, state.nf_calories);
    change(`modification.nf_calories_from_fat`, state.nf_calories_from_fat);
    change(`modification.nf_cholesterol`, state.nf_cholesterol);
    change(`modification.nf_dietary_fiber`, state.nf_dietary_fiber);
    change(`modification.nf_protein`, state.nf_protein);
    change(`modification.nf_saturated_fat`, state.nf_saturated_fat);
    change(`modification.nf_sodium`, state.nf_sodium);
    change(`modification.nf_sugars`, state.nf_sugars);
    change(`modification.nf_total_carbohydrate`, state.nf_total_carbohydrate);
    change(`modification.nf_total_fat`, state.nf_total_fat);
    change(`modification.nf_trans_fat`, state.nf_trans_fat);
    change(`modification.nf_potassium`, state.nf_potassium);
  };

  useEffect(() => {
    fetchData();
    fetchAddon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    let restaurantId;

    if (action === 'add') {
      restaurantId = new URLSearchParams(search).get('restaurant_id');
      setFormdata({ restaurant_id: restaurantId });
      props.initialize({ restaurant_id: restaurantId });
    } else {
      const ret = await nudgeApi.get(`/nutrition/restaurantitems/${params.id}`);
      setFormdata(ret.data.data);
      setPhoto({
        imageDir: ret.data.data.image_dir,
        imageName: ret.data.data.image_name,
      });
      setRating(ret.data.data.rating);
      setAllergens(ret.data.data.allergen);
      setDietaryType(ret.data.data.dietary);
      props.initialize(ret.data.data);
      restaurantId = ret.data.data.brand_id;
    }

    if (restaurantId) {
      const ret2 = await nudgeApi.get(`nutrition/restaurants/${restaurantId}`);
      setRestaurant(ret2.data.data);

      const ret3 = await nudgeApi.get(`/app/restaurantDishTypes/?restaurant_id=${restaurantId}`);
      if (ret3.data) setDishTypes(ret3.data);
    }
  }

  async function fetchAddon() {
    const ret = await nudgeApi.get(`/app/restaurantAddons/?item_id=${params.id}`);
    const addonFull = [];
    let ao = ret.data;

    for (let a of ao) {
      const ret2 = await nudgeApi.get(`/app/restaurantAddonItems/?addon_id=${a.id}`);
      let addonItems = ret2.data;
      addonFull.push({ ...a, addonItems });
    }
    setAddons(addonFull);
  }

  async function submit(values) {
    let datetime = moment().format('YYYY-MM-DD  HH:mm:ss.000');
    const directory = `foods/restaurantitem/${formdata.restaurant_id}`.replace(/ /g, '_');
    let image = { ...photo };

    if (photo.file) {
      try {
        const ret = await uploadPhoto(photo.file, photo.photoName, directory);
        document.getElementById('file-input').value = null;
        setFile(null);
        image = { ...ret };
      } catch (error) {
        console.log(error);
      }
    }

    const validatedValues = validateSubmissionValues(values);
    const data = {
      ...validatedValues,
      rating,
      // In case there is not restaurant_id
      brand_id: values.restaurant_id || values.brand_id,
      image_dir: image.imageDir,
      image_name: image.imageName,
      // Due to formatting of new nutrition API, it conflicts with original design
      category: Array.isArray(values.category) ? values.category : [values.category],
      has_addon: addons.length > 0,
      ingredients: [],
      allergen: allergens,
      dietary: dietaryType,
      modification: {
        mod_type: 'M',
        ...values.modification,
      },
    };

    try {
      if (action === 'add') {
        await nudgeApi.post('/nutrition/restaurantitems', data);
      }

      if (action === 'edit') {
        await nudgeApi.put(`/nutrition/restaurantitems/${params.id}`, data);
      }
      history.push(`/shoppingcart/${restaurant.id}/items/`);
    } catch (e) {
      alert(e);
    }
  }

  async function deleteItem() {
    await nudgeApi.delete(`/nutrition/restaurantitems/${params.id}`);
    history.push(`/shoppingcart/${restaurant.id}/items/`);
  }

  async function updateAddon(id, action, data) {
    if (action === 'add') {
      setAddons([...addons, data]);
    }

    if (action === 'edit') {
      let a2 = addons.map((r) => (r.id === id ? { ...r, ...data } : r));
      setAddons(a2);
    }

    if (action === 'delete') {
      setAddons(addons.filter((r) => r.id !== id));
    }
  }

  return (
    <PageContainer className="pageContentWrapper">
      <div className="pageContentHeader">
        <div>
          <PageTitle title={restaurant.name} />
        </div>
      </div>

      <div>
        <form onSubmit={handleSubmit(submit)}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="editPanel" style={{ height: 'min-content' }}>
              <Field name="name" component={FormInput} label="Item Name" />
              <Field
                name="category"
                component={FormSelect}
                label="Dish Type"
                items={dishTypes.map((d) => ({ value: d.name, label: d.name }))}
              />
              <Field name="insight" component={FormSelect} label="Insight" items={INSIGHT_ARRAY} />
              <Field name="price" type="number" component={FormInput} label="Price" />
              <Field
                name="availability"
                component={FormSelect}
                label="Always Available"
                items={[
                  { key: 'true', value: true, label: 'True' },
                  { key: 'false', value: false, label: 'False' },
                ]}
              />
              <div className="col-12 mt-2">
                <InputLabel>Dietary Types</InputLabel>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  className="auto-tag-components"
                  value={dietaryType || []}
                  onChange={(_, fieldValue) => setDietaryType(fieldValue)}
                  renderInput={(inputParams) => <TextField {...inputParams} variant="outlined" />}
                />
              </div>
              <div className="col-12 mt-2">
                <InputLabel>Allergies</InputLabel>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  className="auto-tag-components"
                  value={allergens || []}
                  onChange={(_, fieldValue) => setAllergens(fieldValue)}
                  renderInput={(inputParams) => <TextField {...inputParams} variant="outlined" />}
                />
              </div>
              <div style={{ padding: '10px' }}>
                <div style={{ padding: '10px 0px', color: '#666' }}>Rating</div>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  size="large"
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ margin: '15px 0px' }}
                onClick={() => setDisplaySearch(true)}
              >
                Ingredient Nutrition Search
              </Button>

              <Field name="description" component={FormInput} label="Description" rows={3} />
              <div style={styles.nutrientContainer}>
                {nutritionFields.map((n) => (
                  <div style={styles.nutrientField}>
                    <Field name={n.name} component={FormInput} label={n.label} />
                  </div>
                ))}
              </div>

              <div style={styles.nutrientField}>
                {servingFields.map((n) => (
                  <div
                    style={{
                      flex: 1,
                      minWidth: '250px',
                    }}
                  >
                    <Field name={n.name} component={FormInput} label={n.label} />
                  </div>
                ))}
                <Field name="upc" component={FormInput} label="UPC Code" />
              </div>
              <div className="formFooter">
                <Button type="submit" variant="contained" color="primary" size="large">
                  Submit
                </Button>
                {action !== 'add' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => deleteItem()}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>

            <div className="editImagePanel">
              <ImageUploadComponent photo={photo} setPhoto={setPhoto} objectFit="cover" />
              <div style={{ padding: '20px' }}></div>
              <ModificationContainer>
                <div style={styles.modificationContent}>Recommended Modification</div>
                <Field
                  name="modification.description"
                  component={FormInput}
                  label="Description"
                  rows={3}
                />
                <div className="recommended-nutrient-container">
                  {nutritionFields.map((n) => (
                    <div style={styles.nutrientField}>
                      <Field
                        name={`modification.${n.name}`}
                        component={FormInput}
                        label={n.label}
                      />
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => setDisplayModSearch(true)}
                  >
                    Modification Ingredient Search
                  </Button>
                </div>
              </ModificationContainer>
              {addons.length > 0 && (
                <div style={styles.addonContainer}>
                  <div style={styles.addonContent}>Addons</div>
                  {addons.map((a) => (
                    <div style={styles.addonGroupContainer}>
                      <div style={styles.addonGroupContent}>
                        <div style={{ fontWeight: '500' }}>
                          <div style={{ fontWeight: 'bold' }}>
                            {a.name}{' '}
                            <span
                              style={{
                                fontSize: '0.8em',
                                fontWeight: 'normal',
                              }}
                            >
                              ({addonTypeLookup[a.addon_type]})
                            </span>
                          </div>
                          {a.image_dir && (
                            <img
                              src={`${a.image_dir}${a.image_name}`}
                              style={styles.photo}
                              alt={a.name}
                            />
                          )}
                        </div>
                        <div>
                          <div
                            className="activeLink"
                            onClick={() => {
                              setAddonAction('edit');
                              setSelectedAddon(a);
                              setShow(true);
                            }}
                          >
                            <FaEdit />
                          </div>
                        </div>
                      </div>

                      {a.addonItems &&
                        a.addonItems.map((item) => (
                          <div style={styles.addonItemContainer}>
                            <div style={styles.addonItemContent}>
                              <div style={{ fontWeight: '500' }}>
                                <div>{item.name}</div>
                                {item.image_dir && (
                                  <img
                                    src={`${item.image_dir}${item.image_name}`}
                                    style={styles.photo}
                                    alt={item.name}
                                  />
                                )}
                              </div>
                              <div>
                                <Link
                                  to={`/restaurant/menuAddonItem/${item.id}/?action=edit&item_id=${params.id}&addon_id=${a.id}&restaurant_id=${formdata.restaurant_id}`}
                                >
                                  <FaEdit />
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      {/* TODO: Uncomment when users are certain this is no longer needed  */}
                      {/* {a.addon_type === 1 ||
                      (a.addon_type === 4 && a.addonItems.length >= 1) ? null : (
                        <div style={{ paddingTop: '10px' }}>
                          <LinkButton
                            label="Add Addon Item"
                            size="small"
                            icon="fas fa-plus"
                            url={`/restaurant/menuAddonItem/new/?action=add&item_id=${params.id}&addon_id=${a.id}&restaurant_id=${formdata.restaurant_id}`}
                          />
                        </div>
                      )} */}
                    </div>
                  ))}
                </div>
              )}

              <div
                style={{
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '70%',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginBottom: '10px' }}
                  onClick={() => {
                    const message =
                      'This will override your current nutrient values for the recommended modification section. Are you sure you want to do this?';
                    if (window.confirm(message) === true) {
                      copyToModification();
                      alert('Nutritional data successfully copied');
                    }
                  }}
                >
                  <i class="fas fa-copy" style={{ paddingRight: '10px' }}></i> Copy Nutrient Info To
                  Modification
                </Button>
                {/* TODO: Uncomment when users are certain this is no longer needed  */}
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setAddonAction('add');
                    setShow(true);
                  }}
                >
                  <i class="fas fa-plus" style={{ paddingRight: '10px' }}></i> Add Addon
                </Button> */}
              </div>
            </div>
          </div>
        </form>
      </div>

      <Modal show={show} size="xl" onHide={() => setShow(false)} modal-90w>
        <Modal.Header closeButton>
          <Modal.Title>{formdata.name} Addon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MenuAddon
            item={formdata}
            addon={selectedAddon}
            action={addonAction}
            restaurantId={formdata.restaurant_id}
            updateHandler={(action, d) => updateAddon(selectedAddon.id, action, d)}
            onHide={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
      <IngredientSearchModal
        displaySearch={displaySearch || displayModSearch}
        setDisplaySearch={displaySearch ? setDisplaySearch : setDisplayModSearch}
        title={formdata.name}
        applyFunction={applySearchChanges}
        setIngredientData={setIngredientData}
        ingredientData={ingredientData}
      />
    </PageContainer>
  );
}
const validate = (formValues) => {
  const errors = {};
  const modificationErrors = {};

  if (!formValues.reference_id) {
    errors.reference_id = 'You must enter reference ID.';
  }

  nutritionFields.forEach((field) => {
    if (formValues[field.name] && isNaN(parseFloat(formValues[field.name]))) {
      errors[field.name] = 'Must be a number';
    }

    if (formValues.modification && formValues.modification[field.name]) {
      const modificationField = formValues.modification[field.name];
      modificationErrors[field.name] = isNaN(parseFloat(modificationField))
        ? 'Must be a number'
        : undefined;
    }
  });

  if (Object.keys(modificationErrors).length > 0) {
    errors.modification = modificationErrors;
  }

  return errors;
};

const styles = {
  modificationContainer: {
    border: '1px solid #ddd',
    margin: '10px 40px 10px 10px',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
  },
  modificationContent: {
    fontSize: '1.2em',
    fontWeight: '500',
  },
  nutrientContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '40px',
  },
  recommendedNutrientContainer: {
    display: 'grid',
    'grid-template-columns': '1fr 1fr',
    paddingTop: '40px',
  },
  nutrientField: {
    flex: 1,
    minWidth: '250px',
  },
  addonContainer: {
    border: '1px solid #ddd',
    margin: '10px 40px 10px 10px',
    padding: '20px',
    borderRadius: '10px',
  },
  addonContent: {
    fontSize: '1.2em',
    fontWeight: '500',
  },
  addonGroupContainer: {
    padding: '10px',
    //border: "1px solid #ddd",
    backgroundColor: '#f3f3f3',
    borderRadius: '8px',
    margin: '15px 0px',
  },
  addonGroupContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    //padding: "10px 0px",
  },
  photo: {
    width: '150px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '10px',
    marginTop: '10px',
  },

  addonItemContainer: {
    margin: '10px 50px 10px 0px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  addonItemContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

const PageContainer = styled.div`
  .MuiFormHelperText-filled {
    background-color: #f5f5f5;
    margin: 0px;
  }
`;

const ModificationContainer = styled.div`
  border: 1px solid #ddd;
  margin: 10px 40px 10px 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f5f5f5;

  .recommended-nutrient-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 40px;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }
`;

let MenuItemReduxForm = reduxForm({
  form: 'mediaForm', // a unique identifier for this form
  validate,
})(MenuItem);

MenuItemReduxForm = connect((state) => ({
  state: state?.form?.mediaForm?.values,
}))(MenuItemReduxForm);

export { MenuItemReduxForm as MenuItem, validateSubmissionValues, nutritionFields };
