import Amplify, { Storage } from "aws-amplify";
import aws_exports from "../aws-exports";

Amplify.configure(aws_exports);

function uploadPhoto(file, photoName, directory) {
  const imageDir =
    "https://testamplifyadmin3b05b324adaf4e949a1a6958725090a124539-dev.s3-us-west-2.amazonaws.com/public/";

  return new Promise((resolve, reject) => {
    if (file && photoName) {
      const fullName = directory + "/" + photoName;
      Storage.put(fullName, file, {
        /* level: 'protected', */
        contentType: file.type,
      })
        .then((result) => {
          resolve({
            imageDir: `${imageDir}${directory}/`,
            imageName: photoName,
          });
        })
        .catch((err) => {
          reject(`Can't upload file: ${err}`);
        });
    } else {
      reject(`Files needed!`);
    }
  });
}

export default uploadPhoto;
