import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import history from '../history';
import Layout from './layout/Layout';
import Navigation from './navigation';

import { store } from '../state';
import { useActions } from '../hooks/useActions';
import { Hub } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react/lib-esm/components';
import MobileChatPage from './livecoach/MobileChatPage';

const signInFields = [
  {
    type: 'username',
    label: 'username',
    placeholder: 'Please enter your Username',
    required: true,
  },
  {
    type: 'password',
    label: 'Password',
    placeholder: 'Please enter your Password',
    required: true,
  },
];

function App() {
  const { fetchCurrentUser } = useActions();
  Hub.listen('auth', (data) => {
    if (data && data.payload.data) {
      console.log(
        'A new auth event has happened: ',
        data.payload.data.username + ' has ' + data.payload.event,
      );
      if (data.payload.data.attributes['custom:userType'] === 'L') {
        history.push('/livecoach/');
      }
    }

    fetchCurrentUser();
  });

  const splitPathName = window.location.pathname.split('/');
  return splitPathName.includes('chat') ? (
    <MobileChatPage />
  ) : (
    <Provider store={store}>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn
          hideSignUp
          headerText="Log in to Nudge Dashboard"
          slot="sign-in"
          usernameAlias="email"
          formFields={signInFields}
        ></AmplifySignIn>
        <Router history={history}>
          <Switch>
            <Layout>
              <Navigation />
            </Layout>
          </Switch>
        </Router>
      </AmplifyAuthenticator>
    </Provider>
  );
}

export default App;
