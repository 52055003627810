import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

function FormSelect({
  input,
  label,
  items = [],
  meta: { touched, error, warning },
  style = { padding: "10px" },
  addEmptyOption = true,
}) {
  return (
    <div style={style}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...input}
        id={input.name}
        variant="outlined"
        fullWidth
        helperText={touched && error}
      >
        {addEmptyOption && (
          <MenuItem value=""></MenuItem>
        )}
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default FormSelect;
