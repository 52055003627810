import { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import IngredientSearch from './ingredientSearch';
import Button from "@material-ui/core/Button";
import styled from 'styled-components';

const IngredientSearchModal = ({
    displaySearch,
    setDisplaySearch,
    title,
    applyFunction,
    setIngredientData,
    ingredientData,
    displayStatus = false,
    confirmButtonText = 'Apply Nutrient Info To Recipe',
}) => {
    const [confirmNotification, setNotification] = useState(false);

    return(
    <Modal
        show={displaySearch}
        size="xl"
        onHide={() => {
            setDisplaySearch(false);
            setNotification(false);
        }}
        dialogClassName="menu-item-search-modal"
    >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ModalContent>
                <IngredientSearch
                    displayLabel={true}
                    displayTitle={false}
                    setIngredientData={setIngredientData}
                    displayStatus={displayStatus}
                    confirmButtonText={confirmButtonText}
                    ingredientData={ingredientData}
                />
                {confirmNotification &&
                <div className="application-success">Nutrition information applied to form!</div>
                }
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="menu-item-search-button"
                    onClick={() => {
                        setNotification(true);
                        applyFunction();
                    }}
                >
                {confirmButtonText}
                </Button>
            </ModalContent>
        </Modal.Body>
    </Modal>
    )
}

const ModalContent = styled.div`
  .menu-item-search-button{
    display: flex;
    margin: auto;
  }

  .application-success{
    color: #80af71;
    text-align: center;
    padding-bottom: 5px;
  }

  padding-bottom: 10px;
`;

export default IngredientSearchModal;