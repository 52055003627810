import { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import FormInput from '../../form/FormInput';
import nudgeApi from '../../../apis/nudgeApi';
import uploadPhoto from '../../../utils/uploadPhoto';
import { addonTypes } from '../../../utils/menu';
import MenuAddonServingOptions from './MenuAddonServingOptions';
import { ImageUploadComponent } from '../../common';

const renderRadio = ({
  input,
  label,
  type,
  rows,
  items = [],
  meta: { touched, error, warning },
}) => {
  return (
    <div style={{ padding: '10px' }}>
      <InputLabel>{label}</InputLabel>

      <RadioGroup
        {...input}
        aria-label="gender"
        id={input.name}
        variant="outlined"
        helperText={touched && error}
        //style={{ display: "flex", flexDirection: "row" }}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
            style={{
              minWidth: '150px',
              margin: '0px',
            }}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

const MenuAddon = (props) => {
  const {
    handleSubmit,
    action,
    item,
    addon,
    updateHandler,
    restaurantId,
    onHide,
    formState,
    change,
  } = props;

  const [formdata, setFormdata] = useState({});
  const [photo, setPhoto] = useState({});
  const [file, setFile] = useState('');
  const [restaurant, setRestaurant] = useState(null);
  const [servingOptions, setServingOptions] = useState([]);
  const QUANTITY_TYPE_ID = '4';
  const addOnType = formState?.values?.addon_type;

  useEffect(() => {
    change('name', null);
  }, [addOnType]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, addon]);

  async function fetchData() {
    const ret = await nudgeApi.get(`/restaurants/${restaurantId}`);
    setRestaurant(ret.data);

    const initServingOptions = [
      { serving_qty: '', serving_unit: '', id: uuidv4(), selected: true },
    ];

    if (action === 'add') {
      setServingOptions(initServingOptions);
    } else {
      const ret = await nudgeApi.get(`/restaurantAddons/${addon.id}`);
      setFormdata(ret.data);
      setPhoto({
        imageDir: ret.data.image_dir,
        imageName: ret.data.image_name,
      });

      props.initialize({
        ...ret.data,
        addon_type: String(ret.data.addon_type),
      });

      setServingOptions(
        ret.data.serving_options && ret.data.serving_options.length > 0
          ? ret.data.serving_options.map((s) => ({ ...s, id: uuidv4() }))
          : initServingOptions,
      );
    }
  }

  async function submit(values) {
    let datetime = moment().format('YYYY-MM-DD  HH:mm:ss.000');

    const directory = `restaurant/${restaurant.name}/addonGroup/`.replace(/ /g, '_');
    let image = { ...photo };

    if (photo.file) {
      try {
        const ret = await uploadPhoto(photo.file, photo.photoName, directory);
        document.getElementById('file-input').value = null;
        setFile(null);
        image = { ...ret };
      } catch (error) {
        console.log(error);
      }
    }

    const data = {
      ...values,
      item_id: item.id,
      restaurant_id: item.restaurant_id,
      serving_options: JSON.stringify(
        servingOptions
          .map((s1) => ({
            serving_qty: s1.serving_qty,
            serving_unit: s1.serving_unit,
            selected: s1.selected,
          }))
          .filter((s2) => s2.serving_qty && s2.serving_unit),
      ),
      image_dir: image.imageDir,
      image_name: image.imageName,
      updated_at: datetime,
      created_at: datetime,
    };

    if (action === 'add') {
      const ret = await nudgeApi.post('/restaurantAddons', data);
      data.id = ret.data.id;
    } else {
      await nudgeApi.put(`/restaurantAddons/${addon.id}`, data);
    }

    updateHandler(action, data);
    onHide();
  }

  async function deleteAddon() {
    await nudgeApi.delete(`/restaurantAddons/${addon.id}`);
    updateHandler('delete');
    onHide();
  }

  return (
    <div style={{ padding: '30px 50px 30px 50px' }}>
      <div style={{ margin: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="editPanel">
            <form onSubmit={handleSubmit(submit)}>
              {addOnType === QUANTITY_TYPE_ID ? null : (
                <Field
                  name="name"
                  component={FormInput}
                  size="small"
                  label="Addon Group/Question"
                />
              )}
              <Field
                name="addon_type"
                component={renderRadio}
                label="Question Type"
                items={addonTypes}
              />
              <div className="formFooter">
                <Button type="submit" variant="contained" color="primary" size="large">
                  Submit
                </Button>
                {action !== 'add' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => deleteAddon()}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </form>
          </div>
          <div className="editImagePanel">
            <ImageUploadComponent photo={photo} setPhoto={setPhoto} objectFit="contain" />
          </div>
        </div>
      </div>
    </div>
  );
};
const validate = (formValues) => {
  const errors = {};

  if (!formValues.reference_id) {
    errors.reference_id = 'You must enter reference ID.';
  }

  return errors;
};

const addOnForm = reduxForm({
  form: 'addonForm', // a unique identifier for this form
  validate,
})(MenuAddon);

const mapStateToProps = (state) => ({
  formState: state.form.addonForm, // <== Inject the form store itself
});

export default connect(mapStateToProps)(addOnForm);
