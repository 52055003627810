export const addonTypes = [
  { value: "1", label: "True/False" },
  { value: "2", label: "Multiple Choice" },
  { value: "3", label: "Multi-Selection" },
  { value: "4", label: "Quantity" },
];

export const addonTypeLookup = {
  1: "True/False",
  2: "Multiple Choice",
  3: "Multi-Selection",
  4: "Quantity",
};
