import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { HiOutlinePhotograph } from 'react-icons/hi';
import imageCompression from 'browser-image-compression';
import PropTypes from 'prop-types';

function ImageUploadComponent({
  photo,
  setPhoto,
  width = '450px',
  height = '250px',
  objectFit = 'cover',
}) {
  const [isUploading, setIsUploading] = useState(false);
  const inputEl = useRef(null);
  const generateImageComponent = (photoInput) => {
    let imageSource;

    if (photoInput.photoFile) {
      imageSource = photoInput.photoFile;
    } else if (photoInput.imageName) {
      imageSource = `${photoInput.imageDir}${photoInput.imageName}`;
    }

    return imageSource ? (
      <img src={imageSource} className="upload-component-image" alt="Home meal food" />
    ) : (
      <div className="default-photo-placeholder">
        <HiOutlinePhotograph size={100} color="#ccc" />
      </div>
    );
  };

  const compressImage = async (event) => {
    event.preventDefault();
    const imageFile = event.target.files[0];
    let compressedFile;

    const options = {
      maxSizeMB: 0.2,
      maxIteration: 30,
    };

    try {
      compressedFile = await imageCompression(imageFile, options);
    } catch (error) {
      setIsUploading(false);
    }

    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    return compressedFile;
  };

  const handleImageUpload = async (e) => {
    try {
      setIsUploading(true);
      const compressedFile = await compressImage(e);
      const photoFile = URL.createObjectURL(compressedFile);
      const photoName = compressedFile.name;
      setPhoto({ file: compressedFile, photoFile, photoName });
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
    }
  };

  return (
    <ImageUploadContainer width={width} height={height} objectFit={objectFit}>
      <div className="foodCard image-upload">{generateImageComponent(photo)}</div>
      <div>{isUploading && <div style={{ textAlign: 'center' }}>Uploading image</div>}</div>
      <section className="image-upload-buttons">
        <input
          className="video-input"
          type="file"
          id="file-input"
          accept="image/*, video/*"
          onChange={handleImageUpload}
          ref={inputEl}
        />
        <button
          type="button"
          onClick={() => {
            setPhoto({});
            inputEl.current.value = null;
          }}
        >
          Clear Image
        </button>
      </section>
    </ImageUploadContainer>
  );
}

const ImageUploadContainer = styled.div`
  padding: 0px 10px;

  .upload-component-image {
    width: 100%;
    height: ${(props) => props.height};
    object-fit: ${(props) => props.objectFit};
  }

  .foodCard.image-upload {
    width: ${(props) => props.width};
    margin-left: 0px;
  }

  .image-upload-buttons {
    margin-top: 10px;
  }

  .default-photo-placeholder {
    height: ${(props) => props.height};
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

ImageUploadComponent.propTypes = {
  photo: PropTypes.shape({
    imageDir: PropTypes.string,
    imageName: PropTypes.string,
  }).isRequired,
  setPhoto: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  objectFit: PropTypes.string.isRequired,
};

export default ImageUploadComponent;
