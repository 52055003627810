import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "@aws-amplify/ui/dist/style.css";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import { SnackbarProvider } from 'notistack';

import App from "./components/App";
import { store } from "./state";
import "./index.css";

Amplify.configure(aws_exports);
ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
