export const sortMessages = (messages) => {
  messages.sort((x, y) => new Date(x.timestamp) - new Date(y.timestamp));
};

/**
 * Returns a new array that appends to and from properties to messages in content
 * @param {Object} content
 * @return {[Object]} - array of messages
 */
export const convertMessageContents = (contents, from = '', to = '', created_at = null) => {
  const result = [];
  contents.forEach((messageContent) => {
    const { timestamp, type } = messageContent;
    let convertedTimestamp;
    if (typeof timestamp === 'string') {
      convertedTimestamp =
        type === 'dialog' ? parseFloat(timestamp, 10) * 1000 : parseFloat(timestamp, 10);
    } else {
      convertedTimestamp = type === 'dialog' ? timestamp * 1000 : timestamp;
    }

    const messageValues = {
      ...messageContent,
      to,
      from,
      timestamp: convertedTimestamp || created_at,
    };
    result.push(messageValues);
  });

  return result;
};

export const scrollToBottomOfChatBox = async (e) => {
  if (e.current) {
    await e.current.scrollIntoView(true);
  }
};
