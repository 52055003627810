import { Auth } from 'aws-amplify';
import nudgeApi from '../../apis/nudgeApi';

export const fetchCurrentUser = () => async (dispatch) => {
  //  const ret = await Auth.currentUserInfo();
  try {
    const ret = await Auth.currentAuthenticatedUser();

    console.log('Auth.currentAuthenticatedUser()', ret);

    if (!ret) return;

    const ret2 = await nudgeApi.get(`/app/staffs/?username=${ret.username}`);

    if (ret2.data.length > 0) {
      ret.profile = ret2.data[0];
    }

    dispatch({ type: 'FETCH_CURRENT_USER', payload: ret });
  } catch (error) {
    console.log('fetchAuthUser error', error);
  }
};

export const deleteCurrentUser = () => async (dispatch) => {
  dispatch({ type: 'DELETE_CURRENT_USER', payload: '' });
};
