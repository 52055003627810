const reducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_TASKS":
      return [...action.payload];
    case "DELETE_CURRENT_USER":
      return { status: "signout" };
    default:
      return state;
  }
};

export default reducer;
