import { ReadyState } from 'react-use-websocket';

export const WEB_SOCKET_CONNECTION_STATUS = {
  CONNECTING: ReadyState.CONNECTING,
  OPEN: ReadyState.OPEN,
  CLOSING: ReadyState.CLOSING,
  CLOSED: ReadyState.CLOSED,
  UNINSTANTIATED: ReadyState.UNINSTANTIATED,
};

export const CHATBOT_NAME = 'B';
export const AI_NAME = 'S';

export const NUTRITIONIX_NUTRIENT_ID = {
  POLYUNSATURATED_FAT: 646,
  TRANS_FAT: 605,
  MONOUNSATURATED_FAT: 645,
  VITAMIN_A: 318,
  VITAMIN_C: 401,
  VITAMIN_D: 324,
  CALCIUM: 301,
  IRON: 303,
};

export const RECOMMENDED_NUTRIENT_INTAKE = {
  TOTAL_FAT: 65, // g
  CALCIUM: 1300, // mg
  DIETARY_FIBRE: 25, // g
  POTASSIUM: 3500, // mg
  VITAMIN_C: 60, // mg
  VITAMIN_D: 400, // IU
  VITAMIN_A: 5000, // IU
  SODIUM: 2400, // mg
  TOTAL_CARBOHYDRATES: 300, // g
  CHOLESTEROL: 300, // mg
  SATURATED_FAT: 20, // g
  IRON: 18, // mg
};

export const UNITS_OF_WEIGHT = {
  GRAM: 'gram',
  KILOGRAM: 'kilogram',
  MILLILITER: 'milliliter',
  OUNCE: 'ounce',
  POUND: 'pound',
  EIGTH_TEASPOON: 'eigth-teaspoon',
  QUARTER_TEASPOON: 'quarter-teaspoon',
  THIRD_TEASPOON: 'third-teaspoon',
  HALF_TEASPOON: 'half-teaspoon',
  THREE_QUARTERS_TEASPOON: 'three-quarters-teaspoon',
  TEASPOON: 'teaspoon',
  TABLESPOON: 'tablespoon',
  QUARTER_CUP: 'quarter-cup',
  THIRD_CUP: 'third-cup',
  HALF_CUP: 'half-cup',
  CUPS: 'cups',
};

export const INSIGHT = {
  DISABLED: { key: '', value: '', label: '' },
  RED: { key: 'red', value: 'red', label: 'Red' },
  YELLOW: { key: 'yellow', value: 'yellow', label: 'Yellow' },
  GREEN: { key: 'green', value: 'green', label: 'Green' },
};

export const INSIGHT_ARRAY = Object.keys(INSIGHT).map((key) => INSIGHT[key]);

export const CUSTOMER_ACCOUNT_STATE = {
  A: 'Activated',
  D: 'Deactivated',
};

export const STATUS_TYPES = {
  A: 'Activated',
  D: 'Deactivated',
  // TODO: Uncomment when we implement permissions
  // P: 'Pending',
};

export const GENERIC_FOOD_TAGS = {
  GENERIC: 'Generic',
  SNACK: 'Snack',
  BREAKFAST: 'Breakfast',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  BRUNCH: 'Brunch',
  SPECIALTY: 'Specialty',
};

export const serverLocation = {
  API_SERVER_URL: process.env.REACT_APP_API_URL,
  WEBSOCKET_SERVER_URL: process.env.REACT_APP_WEBSOCKET_URL,
  RMS_SERVER_URL: process.env.REACT_APP_RMS_URL,
};

export const webSocketServerLocation = {
  SERVER_URL: process.env.REACT_APP_WEBSOCKET_URL,
};

export const MESSAGE__TYPE = {
  TEXT: 'text',
  DIALOG: 'dialog',
  LOCATION: 'location',
  IMAGE: 'image',
  MULTIPLE_IMAGE: 'multiple_images',
  REDIRECT: 'redirect',
};

export const WEIGHT_SELECT_OPTIONS = [
  { key: 'oz', value: 'oz', label: 'oz' },
  { key: 'grams', value: 'grams', label: 'grams' },
  { key: 'lbs', value: 'lbs', label: 'lbs' },
  { key: 'each', value: 'each', label: 'each' },
  { key: 'serving', value: 'serving', label: 'serving' },
  { key: 'cups', value: 'cups', label: 'cups' },
  { key: 'tsp', value: 'tsp', label: 'tsp' },
  { key: 'Tbsp', value: 'Tbsp', label: 'Tbsp' },
  { key: 'mL', value: 'mL', label: 'mL' },
  { key: 'L', value: 'L', label: 'L' },
];

export const FOOD_TYPES = {
  FOOD: 3,
  RECIPE: 4,
  UNPUBLISHED: 5,
};
