import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import userAccessReducer from "./userAccessReducer";

import taskReducer from "./taskReducer";
import smartgoalReducer from "./smartgoalReducer";
import smartgoalLookupReducer from "./smartgoalLookupReducer";
import smartgoalLookupMappingReducer from "./smartgoalLookupMappingReducer";

import storeTypeReducer from "./storeTypeReducer";
import restaurantReducer from "./restaurantReducer";

export default combineReducers({
  userAccess: userAccessReducer,
  tasks: taskReducer,
  smartgoals: smartgoalReducer,
  smartgoalsLookup: smartgoalLookupReducer,
  smartgoalsLookupMapping: smartgoalLookupMappingReducer,

  storeTypes: storeTypeReducer,
  restaurants: restaurantReducer,

  form: formReducer,
});
