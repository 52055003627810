import { Auth } from 'aws-amplify';

const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  const cookie = cookies.find((cookie) => cookie.startsWith(nameEQ));
  return cookie ? cookie.substring(nameEQ.length) : null;
};

const isChat = () => {
  return window?.location?.pathname?.split('/').includes('chat');
};

export function getAccessToken() {
  return new Promise(async (resolve, reject) => {
    try {
      if (isChat()) {
        let token = getCookie('token');
        if (token.includes('Bearer ')) token = token.replace('Bearer ', '');
        resolve(token);
        return;
      }
      const res = await Auth.currentSession();
      const token = res.getAccessToken().getJwtToken();
      if (!token) log.warn('Access token is missing!');
      resolve(token);
    } catch (error) {
      reject(error);
    }
  });
}
