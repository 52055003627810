const reducer = (state = { status: "signout" }, action) => {
  switch (action.type) {
    case "FETCH_CURRENT_USER":
      return { ...action.payload, status: "signin" };
    case "DELETE_CURRENT_USER":
      return { status: "signout" };
    default:
      return state;
  }
};

export default reducer;
