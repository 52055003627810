import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MuiPagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from '../../../utils/customHooks';

const PaginationContainer = styled.div`
  .custom-pagination {
    display: flex;
    justify-content: center;
  }

  .pagination-jump-container {
    padding-top: 10px;
    display: flex;
    align-items: center;
    width: max-content;
    margin: auto;
    font-size: 14px;
  }

  .pagination-input {
    width: 100px;
    margin: 0 10px 0 10px;
  }

  .pagination-button {
    height: 30px;
  }
`;

/**
 * Pagination Component that includes ability to jump to pages. Assumes that
 * "page" url parameter is used to control pages
 */
const Pagination = ({
  count,
  color = 'primary',
  page,
  onChange,
  onParamChange,
  setPage,
  className,
}) => {
  let [jumpNumber, setJumpNumber] = useState(1);
  let history = useHistory();
  const { page: urlPage } = useSearchParams();

  const setEffectData = async () => {
    const newPage = parseInt(urlPage) || 1;
    await onParamChange(newPage);
    setPage(parseInt(newPage));
  };

  useEffect(() => {
    setEffectData();
  }, [urlPage]);

  /**
   * Pushes new page query param to URL
   * @return {void}
   */
  const changePageQuery = (page) => {
    history.push({
      pathname: '',
      search: `?page=${page}`,
    });
  };

  return (
    <PaginationContainer className={className}>
      <MuiPagination
        className="custom-pagination"
        count={count}
        color={color}
        page={page}
        onChange={onChange ? onChange : (_, page) => changePageQuery(page)}
      />
      <div className="pagination-jump-container">
        <div>Jump to page</div>
        <input
          type="number"
          min={1}
          max={count}
          className="pagination-input"
          value={jumpNumber}
          onChange={(e) => setJumpNumber(e.target.value)}
        />
        <Button
          className="pagination-button"
          variant="contained"
          onClick={() => changePageQuery(jumpNumber)}
        >
          Go
        </Button>
      </div>
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  // Total number of pages
  count: PropTypes.number.isRequired,
  // Setter hook function for current page
  setPage: PropTypes.func.isRequired,
  color: PropTypes.string,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  // Function invoked when page url parameter change which takes 1 integer parameter, returns promise
  onParamChange: PropTypes.func,
};

export default Pagination;
