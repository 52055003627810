import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RiAccountCircleFill } from 'react-icons/ri';
import { Auth } from 'aws-amplify';

import { useActions } from '../../hooks/useActions';

import './Layout.css';

function Layout({ children }) {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const { fetchCurrentUser, deleteCurrentUser } = useActions();
  const userAccess = useSelector((state) => state.userAccess);
  const handleDropdownToggle = () => setDropdownToggle(!dropdownToggle);
  const [subItemDropdownToggle, setSubItemDropdownToggle] = useState({});
  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };
  useEffect(() => {
    if (!userAccess || !userAccess.username) {
      // if (!userAccess) {
      fetchCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={sidebarToggle ? 'sb-nav-fixed sb-sidenav-toggled' : 'sb-nav-fixed'}>
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <button
          className="btn btn-link btn-sm  order-lg-0"
          style={{ paddingLeft: 15 }}
          id="sidebarToggle"
          onClick={handleSidebarToggle}
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="navbar-brand">Nudge</div>

        <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
          <ul className="navbar-nav ml-auto ml-md-0">
            <li className={dropdownToggle ? 'nav-item dropdown show' : 'nav-item dropdown'}>
              <div
                id="userDropdown"
                onClick={handleDropdownToggle}
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {userAccess && userAccess.profile && userAccess.profile.photo_url ? (
                  <div>
                    <img
                      src={userAccess.profile.photo_url}
                      style={{
                        width: '40px',
                        height: '40px',
                        objectFit: 'cover',
                        marginRight: '20px',
                        borderRadius: '20px',
                      }}
                      alt="profile pic"
                    />
                  </div>
                ) : (
                  <RiAccountCircleFill size={40} style={{ marginRight: '10px' }} />
                )}
                {userAccess ? userAccess.username : ''}
              </div>
              <div
                className={
                  dropdownToggle
                    ? 'dropdown-menu dropdown-menu-right show'
                    : 'dropdown-menu dropdown-menu-right'
                }
                aria-labelledby="userDropdown"
                onClick={() => setDropdownToggle(false)}
              >
                <Link className="dropdown-item" to={`/profile/`}>
                  Edit Profile
                </Link>
                <div className="dropdown-divider"></div>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    deleteCurrentUser();
                    setDropdownToggle(false);
                    Auth.signOut();
                  }}
                >
                  Logout
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
              <div className="nav pt-3">
                <NavLink className={`nav-link`} to={`/`}>
                  <i className="fas fa-tachometer-alt"></i>
                  CMS
                </NavLink>
                {userAccess &&
                  userAccess.attributes &&
                  userAccess.attributes['custom:userType'] !== 'C' && (
                    <NavLink className={`nav-link`} to={`/staffs/`}>
                      <i className="far fa-user"></i>
                      CMS Users
                    </NavLink>
                  )}

                <NavLink className={`nav-link`} to={`/users/`}>
                  <i className="far fa-user"></i>
                  Customers
                </NavLink>
                <div
                  className={`dropdown ${subItemDropdownToggle['smartGoals'] ? 'open' : ''}`}
                  onClick={() =>
                    setSubItemDropdownToggle({
                      smartGoals: subItemDropdownToggle['smartGoals'] ? false : true,
                    })
                  }
                >
                  <NavLink className={`nav-link`} to={`/smartgoals/`}>
                    <i className="fas fa-chalkboard-teacher"></i>
                    Smart Goals
                    <span className=" nav-link-arrow"></span>
                  </NavLink>

                  <div
                    className="dropdown-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarToggle(false);
                    }}
                  >
                    <NavLink className="nav-link" to={`/smartgoals/lookups/`}>
                      <i className="fas fa-angle-right"></i>Goal lookup
                    </NavLink>
                    <NavLink className="nav-link" to={`/smartgoals/goals/`}>
                      <i className="fas fa-angle-right"></i>Goals
                    </NavLink>
                    <NavLink className="nav-link" to={`/smartgoals/dailyTasks/`}>
                      <i className="fas fa-angle-right"></i>Non configurable task
                    </NavLink>
                    <NavLink className="nav-link" to={`/smartgoals/tasks/`}>
                      <i className="fas fa-angle-right"></i>Tasks
                    </NavLink>
                    <NavLink className="nav-link" to={`/smartgoals/chatbots/`}>
                      <i className="fas fa-angle-right"></i>Chatbot
                    </NavLink>
                    <NavLink className="nav-link" to={`/smartgoals/media/`}>
                      <i className="fas fa-angle-right"></i>Media
                    </NavLink>
                    <NavLink className="nav-link" to={`/smartgoals/digitalCompanions/`}>
                      <i className="fas fa-angle-right"></i>
                      Digital Companion
                    </NavLink>
                  </div>
                </div>

                {/*Meal Logger*/}

                <div
                  className={`dropdown  ${subItemDropdownToggle['mealLogger'] ? 'open' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSubItemDropdownToggle({
                      mealLogger: subItemDropdownToggle['mealLogger'] ? false : true,
                    });
                  }}
                >
                  <NavLink className={`nav-link`} to={`/mealLogger/#/`}>
                    <i className="fas fa-utensils"></i>
                    Meal Logger
                    <span className="nav-link-arrow"></span>
                  </NavLink>
                  <div id="ML_submenu" className="dropdown-content">
                    {/*Start of Home Meal*/}

                    <div
                      className={`dropdown  ${subItemDropdownToggle['homeMeal'] ? 'open' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubItemDropdownToggle({
                          homeMeal: subItemDropdownToggle['homeMeal'] ? false : true,
                          mealLogger: true,
                        });
                      }}
                    >
                      <NavLink className={`nav-link pl-0 pr-0 pt-0`} to={`/mealLogger/homemeal/#/`}>
                        <i className="fas fa-utensils"></i>
                        Home Meal
                        <span className="nav-link-arrow"></span>
                      </NavLink>

                      <div
                        className="dropdown-content ml-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSidebarToggle(false);
                        }}
                      >
                        <NavLink className="nav-link" to={`/mealLogger/homeMeal/cuisine`}>
                          <i className="fas fa-angle-right"></i>Cuisine
                        </NavLink>
                        <NavLink className="nav-link" to={`/mealLogger/homeMeal/category`}>
                          <i className="fas fa-angle-right"></i>Category
                        </NavLink>
                        <NavLink className="nav-link " to={`/homeMeal/foodList/`}>
                          <i className="fas fa-angle-right"></i>Common Food
                        </NavLink>
                        <NavLink className="nav-link " to={`/homeMeal/recipes/`}>
                          <i className="fas fa-angle-right"></i>Recipes
                        </NavLink>
                        {/* <NavLink className="nav-link " to={`/homeMeal/unpublished/`}>
                          <i className="fas fa-angle-right"></i>Unpublished Recipes
                        </NavLink> */}
                      </div>
                    </div>
                    {/*End of home meal*/}
                    {/*Start of Restaurant*/}
                    <div
                      className={`dropdown  ${subItemDropdownToggle['restaurant'] ? 'open' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubItemDropdownToggle({
                          restaurant: subItemDropdownToggle['restaurant'] ? false : true,
                          mealLogger: true,
                        });
                      }}
                    >
                      <NavLink className={`nav-link pl-0 pr-0 pt-0`} to={`/restaurant/list`}>
                        <i className="fas fa-utensils"></i>
                        Restaurant
                        <span className="nav-link-arrow"></span>
                      </NavLink>

                      <div
                        className="dropdown-content ml-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSidebarToggle(false);
                        }}
                      >
                        <NavLink className="nav-link" to={`/mealLogger/restaurant/cuisine`}>
                          <i className="fas fa-angle-right"></i>Cuisine
                        </NavLink>
                      </div>
                    </div>

                    {/*End of Rest*/}

                    <NavLink
                      className="nav-link pl-0 pr-0"
                      to={`/mealLogger/modificationTip/`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubItemDropdownToggle({
                          mealLogger: true,
                        });
                      }}
                    >
                      <i class="far fa-lightbulb"></i>
                      Modification Tip
                    </NavLink>
                    <NavLink
                      className="nav-link pl-0 pr-0"
                      to={`/mealLogger/genericFoodTree/`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubItemDropdownToggle({
                          mealLogger: true,
                        });
                      }}
                    >
                      <i class="fas fa-project-diagram"></i>
                      Generic Food Tree
                    </NavLink>
                  </div>
                </div>

                {/*Excercise Logger*/}
                <div
                  className={`dropdown ${subItemDropdownToggle['exe'] ? 'open' : ''}`}
                  onClick={() =>
                    setSubItemDropdownToggle({
                      exe: subItemDropdownToggle['exe'] ? false : true,
                    })
                  }
                >
                  <NavLink className={`nav-link`} to={`/exerciseLogger/#`}>
                    <i className="fas fa-running"></i>
                    Exercise Logger
                    <span className=" nav-link-arrow"></span>
                  </NavLink>

                  <div
                    className="dropdown-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarToggle(false);
                    }}
                  >
                    <NavLink className="nav-link" exact to={`/exerciseLogger/categoryCardList`}>
                      <i className="fas fa-angle-right"></i>Exercise Category
                    </NavLink>
                    <NavLink className="nav-link" exact to={`/exerciseLogger/bodyPartCardList`}>
                      <i className="fas fa-angle-right"></i>Body Part
                    </NavLink>
                    <NavLink className="nav-link" exact to={`/exerciseLogger/activityCardList`}>
                      <i className="fas fa-angle-right"></i>Exercise Activity
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      exact
                      to={`/exerciseLogger/exerciseByEquipmentList`}
                    >
                      <i className="fas fa-angle-right"></i>Exercise By Equipment
                    </NavLink>
                    <NavLink className="nav-link" exact to={`/exerciseLogger/exerciseDetailList`}>
                      <i className="fas fa-angle-right"></i>Exercise
                    </NavLink>
                    <NavLink className="nav-link" exact to={`/exerciseLogger/tipsList`}>
                      <i className="fas fa-angle-right"></i>Tips
                    </NavLink>
                    <NavLink className="nav-link" exact to={`/exerciseLogger/guidesList`}>
                      <i className="fas fa-angle-right"></i>Guides
                    </NavLink>
                  </div>
                </div>

                {/* Start App Content */}
                <div
                  className={`dropdown ${subItemDropdownToggle['appContent'] ? 'open' : ''}`}
                  onClick={() =>
                    setSubItemDropdownToggle({
                      appContent: subItemDropdownToggle['appContent'] ? false : true,
                    })
                  }
                >
                  <NavLink className={`nav-link`} to={`/appContent/#/`}>
                    <i class="fas fa-seedling"></i>
                    App Content
                    <span className=" nav-link-arrow"></span>
                  </NavLink>

                  <div
                    className="dropdown-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarToggle(false);
                    }}
                  >
                    <NavLink className="nav-link" exact to={`/appContent/#`}>
                      <i className="fas fa-angle-right"></i>Onboarding
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/appContent/1`}>
                      <i className="fas fa-angle-right"></i>Home Screen
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/appContent/2`}>
                      <i className="fas fa-angle-right"></i>Profile Settings
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/appContent/mealLoggerList/`}>
                      <i className="fas fa-angle-right"></i>Meal Logger
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/appContent/3`}>
                      <i className="fas fa-angle-right"></i>Sleep Logger
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/appContent/4`}>
                      <i className="fas fa-angle-right"></i>Exercise Logger
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/appContent/5`}>
                      <i className="fas fa-angle-right"></i>Devices Logger
                    </NavLink>
                  </div>
                </div>
                {/* END App Content */}

                <NavLink className="nav-link" to={`/habit/`}>
                  <i className="fas fa-chart-bar"></i>
                  Habit Analysis
                </NavLink>

                <NavLink className="nav-link" to={`/userDetails/`}>
                  <i className="fas fa-users"></i>
                  User Details
                </NavLink>

                <NavLink className="nav-link" to="/userCreatedRecipes/">
                  <i className="fas fa-utensils" />
                  User Created Recipes
                </NavLink>

                <NavLink className="nav-link" to={`/chatbotTest/`}>
                  <i class="far fa-comments"></i>
                  Chatbot
                </NavLink>
                <div
                  className={`dropdown ${subItemDropdownToggle['c'] ? 'open' : ''}`}
                  onClick={() =>
                    setSubItemDropdownToggle({
                      c: subItemDropdownToggle['c'] ? false : true,
                    })
                  }
                >
                  <NavLink className={`nav-link`} to={`/shoppingcart/29/items/`}>
                    <i className="fas fa-utensils"></i>
                    Masimo Cafe
                    <span className=" nav-link-arrow"></span>
                  </NavLink>

                  <div
                    className="dropdown-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarToggle(false);
                    }}
                  >
                    <NavLink className="nav-link" to={`/shoppingcart/29/items/`}>
                      <i className="fas fa-angle-right"></i>Food
                    </NavLink>
                    <NavLink className="nav-link" to={`/shoppingcart/29/menus/`}>
                      <i className="fas fa-angle-right"></i>Menu Planner
                    </NavLink>

                    <NavLink className="nav-link" to={`/shoppingcart/29/orders/`}>
                      <i className="fas fa-angle-right"></i>Online Order
                    </NavLink>
                    <NavLink className="nav-link" to={`/shoppingcart/29/settings/`}>
                      <i className="fas fa-angle-right"></i>Settings
                    </NavLink>
                  </div>
                </div>
                <div
                  className={`dropdown ${subItemDropdownToggle['c'] ? 'open' : ''}`}
                  onClick={() =>
                    setSubItemDropdownToggle({
                      c: subItemDropdownToggle['c'] ? false : true,
                    })
                  }
                >
                  <NavLink className={`nav-link`} to={`/shoppingcart/10606/items/`}>
                    <i className="fas fa-utensils"></i>
                    Nutu Food
                    <span className=" nav-link-arrow"></span>
                  </NavLink>

                  <div
                    className="dropdown-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarToggle(false);
                    }}
                  >
                    <NavLink className="nav-link" to={`/shoppingcart/10606/items/`}>
                      <i className="fas fa-angle-right"></i>Food
                    </NavLink>
                    <NavLink className="nav-link" to={`/shoppingcart/10606/menus/`}>
                      <i className="fas fa-angle-right"></i>Menu Planner
                    </NavLink>

                    <NavLink className="nav-link" to={`/shoppingcart/10606/orders/`}>
                      <i className="fas fa-angle-right"></i>Online Order
                    </NavLink>
                    <NavLink className="nav-link" to={`/shoppingcart/10606/settings/`}>
                      <i className="fas fa-angle-right"></i>Settings
                    </NavLink>
                  </div>
                </div>
                {/* <NavLink className={`nav-link`} to={`/shoppingcart/`}>
                  <i className="fas fa-shopping-cart"></i>
                  Shopping Cart
                </NavLink> */}
                {/* Live Coach */}
                <NavLink className={`nav-link`} to={`/livecoach/`}>
                  <i className="fas fa-user-cog"></i>
                  Live Coach
                </NavLink>
                {/* Foods */}
                <div
                  className={`dropdown ${subItemDropdownToggle['b'] ? 'open' : ''}`}
                  onClick={() =>
                    setSubItemDropdownToggle({
                      b: subItemDropdownToggle['b'] ? false : true,
                    })
                  }
                >
                  <NavLink className={`nav-link`} to={`/restaurant/cuisines/`}>
                    <i className="fas fa-utensils"></i>
                    Foods (Obsolete)
                    <span className=" nav-link-arrow"></span>
                  </NavLink>

                  <div
                    className="dropdown-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarToggle(false);
                    }}
                  >
                    <NavLink className="nav-link" to={`/restaurant/cuisines/`}>
                      <i className="fas fa-angle-right"></i>Cuisine Categories
                    </NavLink>

                    <NavLink className="nav-link" to={`/restaurant/snackShops/`}>
                      <i className="fas fa-angle-right"></i>Snack Shops
                    </NavLink>
                    <NavLink className="nav-link" to={`/homeMeal/homeSnacks/`}>
                      <i className="fas fa-angle-right"></i>Home Snacks
                    </NavLink>
                    <NavLink className="nav-link" to={`/grocery/storeTypes/`}>
                      <i className="fas fa-angle-right"></i>Store Type
                    </NavLink>
                    <NavLink className="nav-link" to={`/restaurant/list/`}>
                      <i className="fas fa-angle-right"></i>Restaurant List
                    </NavLink>

                    <NavLink className="nav-link" to={`/homeMeal/foodList/`}>
                      <i className="fas fa-angle-right"></i>Food List
                    </NavLink>

                    <NavLink className="nav-link" to={`/restaurant/location/`}>
                      <i className="fas fa-angle-right"></i>Restaurant Location
                    </NavLink>

                    <NavLink className="nav-link" to={`/restaurant/nutritionixSearch/`}>
                      <i className="fas fa-angle-right"></i>Nutritionix Search
                    </NavLink>

                    <NavLink className="nav-link" to={`/restaurant/nutritionixRestaurants/`}>
                      <i className="fas fa-angle-right"></i>Nutritionix Restaurants
                    </NavLink>

                    <NavLink className="nav-link" to={`/foodItemSearch`}>
                      <i className="fas fa-angle-right"></i>Food Item Search
                    </NavLink>
                  </div>
                </div>

                {/* USDA */}
                <div
                  className={`dropdown ${subItemDropdownToggle['usda'] ? 'open' : ''}`}
                  onClick={() =>
                    setSubItemDropdownToggle({
                      usda: subItemDropdownToggle['usda'] ? false : true,
                    })
                  }
                >
                  <NavLink className={`nav-link`} to={`/usda/search/`}>
                    <i class="fas fa-seedling"></i>
                    USDA Foods
                    <span className=" nav-link-arrow"></span>
                  </NavLink>

                  <div
                    className="dropdown-content"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSidebarToggle(false);
                    }}
                  >
                    <NavLink className="nav-link" exact to={`/usda/fullsearch/`}>
                      <i className="fas fa-angle-right"></i>Elastic Search
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/usda/search/`}>
                      <i className="fas fa-angle-right"></i>Regular Search
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/usda/brands/`}>
                      <i className="fas fa-angle-right"></i>Brands
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/usda/categories/`}>
                      <i className="fas fa-angle-right"></i>Categories
                    </NavLink>

                    <NavLink className="nav-link" exact to={`/usda/searchitems/`}>
                      <i className="fas fa-angle-right"></i>Combined Items
                    </NavLink>
                  </div>
                </div>
              </div>
              <NavLink className="nav-link" to="/recipe-analysis/">
                <i className="fas fa-utensils" />
                Recipe Analysis
              </NavLink>
              <NavLink className="nav-link" to="/ingredient-search/">
                <i className="fas fa-search" />
                Ingredient Search
              </NavLink>
              <NavLink className="nav-link" to="/spoonacular">
                <i className="fas fa-search" />
                Spoonacular
              </NavLink>
              <NavLink className="nav-link" to="/education-module/">
                <i className="fas fa-search" />
                Education Modules
              </NavLink>
              <NavLink className="nav-link" to="/dataset-importer/">
                <i className="fas fa-search" />
                Dataset Importer
              </NavLink>
              <NavLink className="nav-link" to="/code-generator/">
                <i className="fas fa-search" />
                Code Generator
              </NavLink>
              <NavLink className="nav-link" to="/risk-survey-results/">
                <i className="fas fa-search" />
                Survey Results
              </NavLink>
            </div>
          </nav>
        </div>

        <div id="layoutSidenav_content" style={{ backgroundColor: '#ddd' }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
