import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';

const BackArrowIconButton = () => {
  const history = useHistory();

  return (
    <ArrowContainer>
      <IconButton className="back-button" onClick={() => history.goBack()}>
        <ArrowBackIos />
      </IconButton>
    </ArrowContainer>
  );
};
/**
 * TODO: Currently global css is overriding icon button with !important which is why it
 * is included here. If we ever decide to refactor css to remove !important, this should also
 * be removed.
 */
const ArrowContainer = styled.div`
  .back-button {
    position: relative !important;
    width: 30px;
    height: 30px;
  }
`;

export default BackArrowIconButton;
