import { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { createFoodReferenceObject, getCustomNutrition } from './ingredientSearchHelper';
import CloseRounded from '@material-ui/icons/CloseRounded';
import { UNITS_OF_WEIGHT } from '../../utils/constants';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';

const NutrientTable = ({
  className,
  foodData,
  setFoodData,
  foodQuantity,
  setFoodQuantity,
  totalNutrition,
  totalServings,
  setServings,
  servings,
  displayStatus = false,
}) => {
  /**
   * Removes specified food item from the list of foods
   * @param {string} foodName - Name of the food item
   *
   * @return {void}
   */
  const onCloseClick = (foodName) => {
    const clone = cloneDeep(foodData);
    const result = clone.filter((food) => food.name !== foodName);
    setFoodData(result);
  };

  useEffect(() => {
    setServings(totalServings);
  }, [totalServings]);

  return (
    <NutrientTableContainer className={className}>
      <Table className="nutrient-table">
        <thead class="thead-dark">
          <tr>
            <th className="nutrient-table-header" scope="col" />
            <th className="nutrient-table-header" scope="col">
              Image
            </th>
            <th className="nutrient-table-header" scope="col">
              Name
            </th>
            {displayStatus && (
              <th className="nutrient-table-header" scope="col">
                Status
              </th>
            )}
            <th className="nutrient-table-header" scope="col">
              Quantity
            </th>
            <th className="nutrient-table-header" scope="col">
              Total Weight
            </th>
            <th className="nutrient-table-header" scope="col">
              Calories
            </th>
            <th className="nutrient-table-header" scope="col">
              Total Fat
            </th>
            <th className="nutrient-table-header" scope="col">
              Saturated Fat
            </th>
            <th className="nutrient-table-header" scope="col">
              Trans Fat
            </th>
            <th className="nutrient-table-header" scope="col">
              Polyunsaturated Fat
            </th>
            <th className="nutrient-table-header" scope="col">
              Monounsaturated Fat
            </th>
            <th className="nutrient-table-header" scope="col">
              Cholesterol
            </th>
            <th className="nutrient-table-header" scope="col">
              Sodium
            </th>
            <th className="nutrient-table-header" scope="col">
              Potassium
            </th>
            <th className="nutrient-table-header" scope="col">
              Total Carbohydrates
            </th>
            <th className="nutrient-table-header" scope="col">
              Dietary FIbre
            </th>
            <th className="nutrient-table-header" scope="col">
              Sugars
            </th>
            <th className="nutrient-table-header" scope="col">
              Protein
            </th>
            <th className="nutrient-table-header" scope="col">
              Vitamin A
            </th>
            <th className="nutrient-table-header" scope="col">
              Vitamin C
            </th>
            <th className="nutrient-table-header" scope="col">
              Vitamin D
            </th>
            <th className="nutrient-table-header" scope="col">
              Calcium
            </th>
            <th className="nutrient-table-header" scope="col">
              Iron
            </th>
          </tr>
        </thead>
        <tbody>
          {foodData.map((food, key) => {
            const photoUrl = get(food, 'image_dir', '');
            const servingQuantity = get(
              foodQuantity[food.name],
              'servingQuantity',
              food.serving_qty,
            );
            const measurementType = get(
              foodQuantity[food.name],
              'measurementType',
              UNITS_OF_WEIGHT.GRAM,
            );
            const servingSize = get(
              foodQuantity[food.name],
              'servingSize',
              food.serving_metric_qty / food.serving_size_qty,
            );
            const foodQuantityInfo = { servingQuantity, measurementType, servingSize };
            const foodReference = createFoodReferenceObject(food);
            console.log('Food reference is: ', foodReference);

            return (
              <tr key={key}>
                <td>
                  <CloseRounded
                    className="nutrient-table-close"
                    onClick={() => onCloseClick(food.name)}
                  />
                </td>
                <td>
                  <img alt={food.name} className="nutrient-table-image" src={photoUrl} />
                </td>
                <td>{food.name}</td>
                {displayStatus && foodQuantity[food.name] && (
                  <td>
                    <Switch
                      color="primary"
                      checked={foodQuantity[food.name].status === 'A'}
                      onChange={() => {
                        const clone = { ...foodQuantity };
                        const status = clone[food.name].status;
                        clone[food.name].status = status === 'A' ? 'D' : 'A';
                        setFoodQuantity(clone);
                      }}
                    />
                  </td>
                )}
                <td className="nutrition-weight-inputs">
                  <input
                    type="number"
                    min="0"
                    className="nutrition-table-input quantity"
                    value={servingSize}
                    onChange={(e) => {
                      const input = e.target.value || 0;
                      const clone = { ...foodQuantity };
                      clone[food.name].servingSize = parseFloat(input);
                      setFoodQuantity(clone);
                    }}
                  />
                  <select
                    className="nutrition-table-select"
                    value={measurementType}
                    onChange={(e) => {
                      const clone = { ...foodQuantity };
                      clone[food.name].measurementType = e.target.value;
                      setFoodQuantity(clone);
                    }}
                  >
                    <optgroup label="Metric Units">
                      <option value={UNITS_OF_WEIGHT.GRAM}>g</option>
                      <option value={UNITS_OF_WEIGHT.KILOGRAM}>kg</option>
                      <option value={UNITS_OF_WEIGHT.MILLILITER}>ml</option>
                    </optgroup>
                    <optgroup label="Imperial Units">
                      <option value={UNITS_OF_WEIGHT.OUNCE}>ounce</option>
                      <option value={UNITS_OF_WEIGHT.POUND}>pound</option>
                    </optgroup>
                    <optgroup label="Teaspoon (2.5g)">
                      <option value={UNITS_OF_WEIGHT.TEASPOON}>1 tsp</option>
                      <option value={UNITS_OF_WEIGHT.THREE_QUARTERS_TEASPOON}>3/4 tsp</option>
                      <option value={UNITS_OF_WEIGHT.HALF_TEASPOON}>1/2 tsp</option>
                      <option value={UNITS_OF_WEIGHT.THIRD_TEASPOON}>1/3 tsp</option>
                      <option value={UNITS_OF_WEIGHT.QUARTER_TEASPOON}>1/4 tsp</option>
                      <option value={UNITS_OF_WEIGHT.EIGTH_TEASPOON}>1/8 tsp</option>
                    </optgroup>
                    <optgroup label="Teaspoon (12.5g)">
                      <option value={UNITS_OF_WEIGHT.TABLESPOON}>1 tbsp</option>
                    </optgroup>
                    <optgroup label="Cup (128g)">
                      <option value={UNITS_OF_WEIGHT.CUPS}>1 cup</option>
                      <option value={UNITS_OF_WEIGHT.HALF_CUP}>1/2 cup</option>
                      <option value={UNITS_OF_WEIGHT.THIRD_CUP}>1/3 cup</option>
                      <option value={UNITS_OF_WEIGHT.QUARTER_CUP}>1/4 cup</option>
                    </optgroup>
                  </select>
                </td>
                <td>{getCustomNutrition(foodReference.servingWeightInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.calorieInfo, foodQuantityInfo)}</td>
                <td>{getCustomNutrition(foodReference.totalFatInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.saturatedFatInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.transFatInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.polyFatInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.monoFatInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.cholesterolInfo, foodQuantityInfo)}mg</td>
                <td>{getCustomNutrition(foodReference.sodiumInfo, foodQuantityInfo)}mg</td>
                <td>{getCustomNutrition(foodReference.potassiumInfo, foodQuantityInfo)}mg</td>
                <td>{getCustomNutrition(foodReference.carbInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.dietaryFibreInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.sugarInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.proteinInfo, foodQuantityInfo)}g</td>
                <td>{getCustomNutrition(foodReference.vitaminAInfo, foodQuantityInfo)} IU</td>
                <td>{getCustomNutrition(foodReference.vitaminCInfo, foodQuantityInfo)}mg</td>
                <td>{getCustomNutrition(foodReference.vitaminDInfo, foodQuantityInfo)} IU</td>
                <td>{getCustomNutrition(foodReference.calciumInfo, foodQuantityInfo)}mg</td>
                <td>{getCustomNutrition(foodReference.ironInfo, foodQuantityInfo)}mg</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={displayStatus ? 5 : 4}>
              <strong>Total Nutrients for </strong>
              <input
                type="number"
                min="0"
                className="nutrition-table-input"
                value={servings}
                onChange={(e) => {
                  const inputValue = parseInt(e.target.value) || 0;
                  setServings(inputValue);
                }}
              />
              <strong> serving(s):</strong>
            </td>
            <td>
              <strong>{totalNutrition.totalWeight}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.calories}</strong>
            </td>
            <td>
              <strong>{totalNutrition.totalFat}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.saturatedFat}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.transFat}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.polyunsaturatedFat}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.monounsaturatedFat}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.cholesterol}mg</strong>
            </td>
            <td>
              <strong>{totalNutrition.sodium}mg</strong>
            </td>
            <td>
              <strong>{totalNutrition.potassium}mg</strong>
            </td>
            <td>
              <strong>{totalNutrition.totalCarbs}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.dietaryFiber}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.sugars}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.protein}g</strong>
            </td>
            <td>
              <strong>{totalNutrition.vitaminA} IU</strong>
            </td>
            <td>
              <strong>{totalNutrition.vitaminC}mg</strong>
            </td>
            <td>
              <strong>{totalNutrition.vitaminD} IU</strong>
            </td>
            <td>
              <strong>{totalNutrition.calcium}mg</strong>
            </td>
            <td>
              <strong>{totalNutrition.iron}mg</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </NutrientTableContainer>
  );
};

const NutrientTableContainer = styled.div`
  overflow-x: scroll;
  .nutrient-table {
    min-width: 3000px;
  }

  .nutrient-table-header {
    font-size: 12px;
  }

  .nutrient-table-header.smaller-text {
    font-size: 11px;
  }

  .nutrient-table-image {
    width: 35px;
    height: 35px;
  }

  .nutrient-table-close:hover {
    cursor: pointer;
  }

  .custom-serving-div {
    display: flex;
  }

  .nutrition-table-input {
    width: 50px;
  }

  .nutrition-table-input.quantity {
    width: 75px;
  }

  .nutrition-table-select {
    height: 30px;
    margin-left: 10px;
    width: 150px;
  }

  .nutrition-weight-inputs {
    display: flex;
  }
`;

NutrientTable.propTypes = {
  className: PropTypes.string,
  foodData: PropTypes.string.isRequired,
  setFoodData: PropTypes.func.isRequired,
  foodQuantity: PropTypes.object.isRequired,
  setFoodQuantity: PropTypes.func.isRequired,
  totalNutrition: PropTypes.object.isRequired,
  totalServings: PropTypes.number.isRequired,
  servings: PropTypes.number.isRequired,
  setServings: PropTypes.func.isRequired,
  displayStatus: PropTypes.bool,
};

export default NutrientTable;
