import React, { useState, useRef, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'emoji-mart/css/emoji-mart.css';
import MessageBubble from './MessageBubble';
import { getConnectionMessage } from '../../../utils/websocketHelpers';
import { MessagePropType } from '../../../utils/propTypes';
import { scrollToBottomOfChatBox, convertMessageContents } from './chatBoxHelper';
import { WEB_SOCKET_CONNECTION_STATUS, CHATBOT_NAME } from '../../../utils/constants';

const Picker = React.lazy(() =>
  import('emoji-mart').then((module) => ({ default: module.Picker })),
);

function ChatBox({
  onMessageSend,
  username,
  messages = [],
  connectionState,
  displayConnection = true,
  mobileView = false,
  handleChatScroll,
}) {
  const connectionIsClosed = WEB_SOCKET_CONNECTION_STATUS.CLOSED === connectionState;
  const [messageText, setMessageText] = useState('');
  const [displayPicker, setDisplayPicker] = useState(false);
  const [previousRecentMessage, setMostRecentMessage] = useState({ contents: [] });
  const endMessageRef = useRef(null);

  useEffect(() => {
    const latestMessage = messages[messages.length - 1];
    const noPreviousMessage = Object.keys(previousRecentMessage).length === 0;
    if (messages.length > 0) {
      setMostRecentMessage(latestMessage);
    }

    if (endMessageRef.current && messages.length > 0) {
      const noRecentMessages =
        `${latestMessage.contents[0]?.timestamp}` ===
        `${previousRecentMessage.contents[0]?.timestamp}`;
      if (noPreviousMessage || !noRecentMessages) {
        scrollToBottomOfChatBox(endMessageRef);
      }
    }
  }, [messages]);

  const generateMessages = (allMessages) => {
    const convertedMessages = [];
    allMessages.forEach((message) => {
      if (message.contents) {
        convertedMessages.push(
          ...convertMessageContents(message.contents, message.from, message.to),
        );
      }
    });

    const result = convertedMessages.map((convertedMessage, index) => {
      const messageFromUser = convertedMessage.from === username;
      const messageFromChatBot = convertedMessage.from === CHATBOT_NAME;
      const messageFromAdmin = messageFromUser || messageFromChatBot;
      return (
        <div
          key={index}
          className={messageFromAdmin ? 'chatbox-messages right' : 'chatbox-messages'}
        >
          <MessageBubble
            messageSender={messageFromAdmin ? 'COACH' : convertedMessage.from}
            messageText={
              convertedMessage.type === 'dialog' ? convertedMessage.title : convertedMessage.message
            }
            alignRight={messageFromAdmin}
            backgroundColor={messageFromAdmin ? '#EC7600' : undefined}
            textColor={messageFromAdmin ? 'white' : undefined}
            nameTextColor={messageFromAdmin ? '#EC7600' : undefined}
            timestamp={convertedMessage.timestamp}
          />
        </div>
      );
    });

    return result;
  };

  const generateConnectionElement = (connectionStatus) => {
    let message = null;
    if (connectionIsClosed) {
      message = (
        <span className="connection-text error">
          Connection is closed, please refresh or wait for reconnection
        </span>
      );
    } else {
      message = (
        <span className="connection-text">
          Connection Status:
          {getConnectionMessage(connectionStatus)}
        </span>
      );
    }

    return message;
  };

  const addEmoji = (e) => {
    const sym = e.unified.split('-');
    const codesArray = [];
    sym.forEach((el) => codesArray.push(`0x${el}`));
    const emoji = String.fromCodePoint(...codesArray);
    setMessageText(messageText + emoji);
  };

  return (
    <Container mobileView={mobileView}>
      <ChatBoxContainer mobileView={mobileView}>
        <div className="message-container" onScroll={handleChatScroll}>
          {generateMessages(messages)}
          <div ref={endMessageRef} />
        </div>
        <form className="chatbox-input-container">
          <textarea
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            className="chat-textbox"
            disabled={connectionIsClosed}
            placeholder="Send a Message..."
            onKeyPress={
              !mobileView
                ? (e) => {
                    if (e.key === 'Enter' && messageText.trim() !== '') {
                      onMessageSend(e.target.value);
                      setMessageText('');
                      e.preventDefault();
                    }
                  }
                : undefined
            }
          />
          <div className="chatbox-input-icons">
            <i
              className="far fa-smile chatbox-icons"
              onClick={connectionIsClosed ? null : () => setDisplayPicker(!displayPicker)}
            />
            <Suspense fallback={<></>}>
              {displayPicker && (
                <Picker
                  style={{ position: 'absolute', right: '100px', top: '370px' }}
                  onSelect={(e) => {
                    addEmoji(e);
                    setDisplayPicker(false);
                  }}
                />
              )}
            </Suspense>
            <i className="fas fa-paperclip chatbox-icons" />
          </div>
        </form>
      </ChatBoxContainer>
      {displayConnection ? generateConnectionElement(connectionState) : null}
    </Container>
  );
}

const ChatBoxContainer = styled.div`
  border: 5px solid #ebf0f6;
  height: 100%;

  .chatbox-messages {
    margin-bottom: 12px;
  }

  .chatbox-messages.right {
    display: flex;
    justify-content: flex-end;
  }

  .chatbox-header {
    padding-left: 20px;
  }

  .message-container {
    height: ${(props) => (props.mobileView ? '88%' : '280px')};
    border-bottom: 2px solid #ebf0f6;
    padding: 0px 20px;
    overflow-y: scroll;
  }

  .chatbox-input-container {
    min-height: ${(props) => (props.mobileView ? '0px' : '70px')};
    display: flex;
    flex-direction: row;
  }

  .chat-textbox {
    resize: none;
    border: none;
    width: 75%;
    height: 100%;
    color: #c8d5de;
  }

  .chatbox-input-icons {
    width: 25%;
    display: flex;
    justify-content: space-evenly;
  }

  .chatbox-icons {
    font-size: 25px;
    line-height: ${(props) => (props.mobileView ? '25px' : '70px')};
    color: #dcdcdc;
  }

  .chatbox-icons:hover {
    cursor: pointer;
    color: grey;
  }

  .chatbox-emoji-picker {
    position: absolute;
  }
`;

const Container = styled.div`
  height: ${(props) => (props.mobileView ? '100vh' : 'initial')};
  .connection-text {
    font-size: 12px;
  }

  .connection-text.error {
    color: red;
  }
`;

ChatBox.propTypes = {
  onMessageSend: PropTypes.func.isRequired,
  username: PropTypes.string,
  messages: PropTypes.arrayOf(MessagePropType),
  connectionState: PropTypes.number,
  displayConnection: PropTypes.bool,
  mobileView: PropTypes.bool,
  handleChatScroll: PropTypes.func.isRequired,
};

export default ChatBox;
