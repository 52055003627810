import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DelayContainer = ({
  wait = 0,
  children,
  className,
  functionAfterWait = () => {},
}) => {
  const [hidden, setHidden] = useState(wait > 0);
  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
      if (functionAfterWait) {
        functionAfterWait();
      }
    }, wait);
  }, []);

  return (
    <DelayStyleContainer className={className} style={{ display: hidden ? 'none' : '' }}>
      {children}
    </DelayStyleContainer>
  );
};

const DelayStyleContainer = styled.div`
`;

export default DelayContainer;
