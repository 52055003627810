import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

function FormInput({
  input,
  label,
  rows,
  size = 'medium',
  padding = '10px',
  meta: { touched, error, warning },
}) {
  return (
    <div style={{ padding }}>
      <InputLabel>{label}</InputLabel>
      <TextField
        multiline
        error={touched && error ? true : false}
        {...input}
        id={input.name}
        variant="outlined"
        size={size}
        fullWidth
        helperText={touched && error}
        rows={rows}
        style={{ backgroundColor: '#fff' }}
      />
    </div>
  );
}

export default FormInput;
