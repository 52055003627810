import PropTypes from 'prop-types';

export const MessagePropType = PropTypes.shape({
  from: PropTypes.string.isRequired,
  to: PropTypes.string,
  created_on: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    timestamp: PropTypes.number,
  }),
});

export const SelectedCategoryPropType = PropTypes.shape({
  category_name: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  cuisine: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  image_dir: PropTypes.string,
  image_name: PropTypes.string,
  parent_id: PropTypes.number,
  position: PropTypes.number,
  search_phases: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  updated_at: PropTypes.string,
});
