import React from 'react';
import styled from 'styled-components';
import { RECOMMENDED_NUTRIENT_INTAKE } from '../../utils/constants';
import PropTypes from 'prop-types';

const getDailyValuePercent = (
  currentValue,
  dailyValue,
  dailyCaloricIntake,
  roundDecimalPlace = 0,
) => {
  // Used as a reference since it is the standard for measuring daily value.
  const standardCaloricIntake = 2000;
  const caloricIntakeMultiplier = dailyCaloricIntake / standardCaloricIntake;
  const newDailyValue = dailyValue * caloricIntakeMultiplier;
  const percentValue = (currentValue / newDailyValue) * 100;

  return percentValue.toFixed(roundDecimalPlace);
};

const NutritionLabel = ({
  servingSize,
  calories,
  totalFat,
  saturatedFat,
  transFat,
  caloriesFromFat,
  polyUnsaturatedFat,
  monoUnsaturatedFat,
  cholesterol,
  sodium,
  potassium,
  totalCarbs,
  dietaryFibre,
  sugar,
  protein,
  vitaminA,
  vitaminC,
  vitaminD,
  calcium,
  iron,
  dailyIntake = 2000,
  className,
  valuesToExclude = [],
  maxWidth,
}) => (
  <NutritionLabelContainer className={className} maxWidth={maxWidth}>
    <div className="nutrition-title">Nutrition Facts</div>
    <div>Serving Size {servingSize}g</div>
    <div className="bar-separator" />
    <div className="nutrition-line">
      <strong>Amount Per Serving</strong>
    </div>
    <div className="nutrition-line">
      <div>
        <strong>Calories </strong>
        <span>{calories}</span>
      </div>
      <div>
        <div>Calories from Fat {caloriesFromFat}</div>
      </div>
    </div>
    <div className="bar-separator-small" />
    <div className="nutrition-line-align-right">
      <strong>% Daily Value</strong>
    </div>
    <div className="nutrition-line">
      <div>
        <strong>Total Fat </strong>
        <span>{totalFat}g</span>
      </div>
      <strong>
        {getDailyValuePercent(totalFat, RECOMMENDED_NUTRIENT_INTAKE.TOTAL_FAT, dailyIntake)}%
      </strong>
    </div>
    {!valuesToExclude.includes('saturatedFat') && (
      <div className="nutrition-line indent">
        <div>
          Saturated Fat
          <span> {saturatedFat}g</span>
        </div>
        <strong>
          {getDailyValuePercent(
            saturatedFat,
            RECOMMENDED_NUTRIENT_INTAKE.SATURATED_FAT,
            dailyIntake,
          )}
          %
        </strong>
      </div>
    )}
    {transFat ? (
      <div className="nutrition-line indent">
        <div>
          Trans Fat
          <span> {transFat}g</span>
        </div>
      </div>
    ) : null}
    {polyUnsaturatedFat ? (
      <div className="nutrition-line indent">
        <div>
          Polyunsaturated Fat
          <span> {polyUnsaturatedFat}g</span>
        </div>
      </div>
    ) : null}
    {monoUnsaturatedFat ? (
      <div className="nutrition-line indent">
        <div>
          Monounsaturated Fat
          <span> {monoUnsaturatedFat}g</span>
        </div>
      </div>
    ) : null}
    <div className="nutrition-line">
      <div>
        <strong>Cholesterol </strong>
        <span>{cholesterol}mg</span>
      </div>
      <strong>
        {getDailyValuePercent(cholesterol, RECOMMENDED_NUTRIENT_INTAKE.CHOLESTEROL, dailyIntake)}%
      </strong>
    </div>
    <div className="nutrition-line">
      <div>
        <strong>Sodium </strong>
        <span>{sodium}mg</span>
      </div>
      <strong>
        {getDailyValuePercent(sodium, RECOMMENDED_NUTRIENT_INTAKE.SODIUM, dailyIntake)}%
      </strong>
    </div>
    <div className="nutrition-line">
      <div>
        <strong>Potassium </strong>
        <span>{potassium}mg</span>
      </div>
      <strong>
        {getDailyValuePercent(potassium, RECOMMENDED_NUTRIENT_INTAKE.POTASSIUM, dailyIntake)}%
      </strong>
    </div>
    <div className="nutrition-line">
      <div>
        <strong>Total Carbohydrates </strong>
        <span>{totalCarbs}g</span>
      </div>
      <strong>
        {getDailyValuePercent(
          totalCarbs,
          RECOMMENDED_NUTRIENT_INTAKE.TOTAL_CARBOHYDRATES,
          dailyIntake,
        )}
        %
      </strong>
    </div>
    <div className="nutrition-line indent">
      <div>
        Dietary Fibre
        <span> {dietaryFibre}g</span>
      </div>
      <strong>
        {getDailyValuePercent(dietaryFibre, RECOMMENDED_NUTRIENT_INTAKE.DIETARY_FIBRE, dailyIntake)}
        %
      </strong>
    </div>
    <div className="nutrition-line indent">
      <div>
        Sugars
        <span> {sugar}g</span>
      </div>
    </div>
    <div className="nutrition-line">
      <div>
        <strong>Protein </strong>
        <span>{protein}g</span>
      </div>
    </div>
    <div className="bar-separator small-margin" />
    {vitaminA ? (
      <div className="nutrition-line">
        <div>
          <strong>Vitamin A </strong>
          <span>{vitaminA}IU</span>
        </div>
        <span>
          {getDailyValuePercent(vitaminA, RECOMMENDED_NUTRIENT_INTAKE.VITAMIN_A, dailyIntake, 1)}%
        </span>
      </div>
    ) : null}
    {vitaminC ? (
      <div className="nutrition-line">
        <div>
          <strong>Vitamin C </strong>
          <span>{vitaminC}mg</span>
        </div>
        <span>
          {getDailyValuePercent(vitaminC, RECOMMENDED_NUTRIENT_INTAKE.VITAMIN_C, dailyIntake, 1)}%
        </span>
      </div>
    ) : null}
    {vitaminD ? (
      <div className="nutrition-line">
        <div>
          <strong>Vitamin D </strong>
          <span>{vitaminD}IU</span>
        </div>
      </div>
    ) : null}
    {calcium ? (
      <div className="nutrition-line">
        <div>
          <strong>Calcium </strong>
          <span>{calcium}mg</span>
        </div>
        <span>
          {getDailyValuePercent(calcium, RECOMMENDED_NUTRIENT_INTAKE.CALCIUM, dailyIntake, 1)}%
        </span>
      </div>
    ) : null}
    {iron && (
      <div className="nutrition-line">
        <div>
          <strong>Iron </strong>
          <span>{iron}mg</span>
        </div>
        <span>{getDailyValuePercent(iron, RECOMMENDED_NUTRIENT_INTAKE.IRON, dailyIntake, 1)}%</span>
      </div>
    )}
    <div className="bar-separator-small" />
    <div className="daily-value-text">
      * Percentage Daily Values are based on a 2,000 calorie diet. Your Daily Values may be higher
      or lower depending on your calorie needs
    </div>
  </NutritionLabelContainer>
);
const NutritionLabelContainer = styled.div`
  padding: 8.5px;
  border: 1px solid black;
  font-family: Arial;
  font-size: 79%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};

  .nutrition-title {
    font-size: 2.3em;
    font-weight: 800;
    font-family: Helvetica;
  }

  .bar-separator {
    height: 10px;
    margin-top: 10px;
    background-color: #333333;
  }
  .bar-separator.small-margin {
    margin-top: 5px;
  }

  .bar-separator-small {
    height: 5px;
    background-color: #333333;
  }

  .nutrition-line {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #333333;
  }

  .nutrition-line-align-right {
    display: flex;
    justify-content: flex-end;
  }

  .nutrition-line.indent {
    margin-left: 15px;
  }

  .daily-value-text {
    font-size: 11px;
    line-height: 15px;
  }
`;

NutritionLabel.propTypes = {
  servingSize: PropTypes.number,
  calories: PropTypes.number,
  totalFat: PropTypes.number,
  saturatedFat: PropTypes.number,
  transFat: PropTypes.number,
  polyUnsaturatedFat: PropTypes.number,
  monoUnsaturatedFat: PropTypes.number,
  cholesterol: PropTypes.number,
  sodium: PropTypes.number,
  potassium: PropTypes.number,
  totalCarbs: PropTypes.number,
  dietaryFibre: PropTypes.number,
  sugar: PropTypes.number,
  protein: PropTypes.number,
  vitaminA: PropTypes.number,
  vitaminC: PropTypes.number,
  vitaminD: PropTypes.number,
  calcium: PropTypes.number,
  iron: PropTypes.number,
  dailyIntake: PropTypes.number,
};

export default NutritionLabel;
