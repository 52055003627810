import {WEB_SOCKET_CONNECTION_STATUS} from './constants'

/**
 * Gets connection status message from websocketß
 * @param {string} readyState - String constant from use-websocket
 * @returns {string} - The connection status message
 */
export const getConnectionMessage = (readyState) => ({
        [WEB_SOCKET_CONNECTION_STATUS.CONNECTING]: 'Connecting',
        [WEB_SOCKET_CONNECTION_STATUS.OPEN]: 'Open',
        [WEB_SOCKET_CONNECTION_STATUS.CLOSING]: 'Closing',
        [WEB_SOCKET_CONNECTION_STATUS.CLOSED]: 'Closed',
        [WEB_SOCKET_CONNECTION_STATUS.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState]
);