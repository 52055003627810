import nudgeApi from '../../apis/nudgeApi';
export const fetchTasks = () => async (dispatch) => {
  //  const ret = await Auth.currentUserInfo();
  try {
    const ret = await nudgeApi.get('/app/tasks');
    dispatch({ type: 'FETCH_TASKS', payload: ret.data });
  } catch (error) {
    console.log('fetchTasks error', error);
  }
};

export const deleteCurrentUser = () => async (dispatch) => {
  dispatch({ type: 'DELETE_CURRENT_USER', payload: '' });
};
