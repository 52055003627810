import nudgeApi from "../../apis/nudgeApi";

export const fetchCuisineRestuarants = (name) => async (dispatch) => {
  console.log("name", name);
  try {
    const ret = await nudgeApi.get(`/restaurants/?cuisine_type=${name}`);
    dispatch({ type: "FETCH_CUSINE_RESTAURANTS", payload: ret.data });
  } catch (error) {
    console.log("error", error);
  }
};
