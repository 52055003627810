import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import styled from "styled-components";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { routeList } from '../../utils/routeList';

const Breadcrumbs = ({ links, breadcrumbs }) => {
    // Will automatically generate breadcrumb links unless the link prop is passed through
    const breadcrumbLinks = links?
        links.map((link) => (<Link className="crumb-link" to={link.url} >{link.text}</Link>))
        : breadcrumbs.map(({ match, breadcrumb }) => (<Link className="crumb-link" to={match.url} >{breadcrumb}</Link>));
    
    return (
    <Container>
        {breadcrumbLinks.reduce((prev, curr) => [prev, ' > ', curr])}
    </Container>
)};


const Container = styled.div`
    color: #68b3f7;
    font-size: 14px;
    .crumb-link {
        color: #68b3f7;
        font-size: 14px;
        padding-right: 0.3rem;
    }

    .crumb-link:not(:first-child) {
        padding-left: 0.3rem;
    }

    .crumb-link:not(:first-child) {
        padding-left: 0.3rem;
    }

    .crumb-link + .crumb-link::before {
    }

    .crumb-link:last-child {
        color: #6c757d;
        pointer-events: none;
    }
`;

Breadcrumbs.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string,
    })),
};

export default withBreadcrumbs(routeList)(Breadcrumbs);