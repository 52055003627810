import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MessageBubble from './MessageBubble';
import { MessagePropType } from '../../../utils/propTypes';
import { scrollToBottomOfChatBox, convertMessageContents } from './chatBoxHelper';
import {
  WEB_SOCKET_CONNECTION_STATUS,
  MESSAGE__TYPE,
  CHATBOT_NAME,
  AI_NAME,
} from '../../../utils/constants';
import DelayContainer from './DelayContainer';
import ChatInput from './ChatInput';

function MobileChatBox({
  username,
  messages = [],
  connectionState,
  sendJsonMessage,
  setMessageHistory,
  handleChatScroll,
  displayMobileSendMessage = true,
  autoScrollBottom = true,
  loading,
  setLoading,
  lastMessage,
  fetchPreviousMessages,
  token,
}) {
  const [messageText, setMessageText] = useState('');
  const [previousRecentMessage, setMostRecentMessage] = useState({ contents: [] });
  const endMessageRef = useRef(null);
  const [newMessages, setNewMessages] = useState([]);
  const [displayMobileChatbox, setDisplayChatbox] = useState(displayMobileSendMessage);
  // Used to help determine when loading animation should load
  const [messagesToDisplay, setMessagestoDisplay] = useState(0);
  const delayIncrement = 1000;
  const enterMobileMessage = (messageTosend) => {
    const message = {
      from: username,
      to: '',
      contents: [
        {
          type: 'text',
          message: messageTosend,
          timestamp: new Date().valueOf(),
        },
      ],
    };

    if (messageTosend.trim() !== '') {
      sendJsonMessage(message);
      setNewMessages([...newMessages, message]);
    }
  };

  const handleReceivedMessage = async (receivedMessage) => {
    if (receivedMessage && receivedMessage.data) {
      let jsonError = false;
      let jsonMessage = '';
      try {
        jsonMessage = JSON.parse(receivedMessage.data);
      } catch (e) {
        jsonError = true;
      }
      if (!jsonError) {
        // Handles redirect
        if (jsonMessage?.contents[0].type === MESSAGE__TYPE.REDIRECT) {
          setTimeout(() => {
            if (window && window.webkit) {
              // Used to call Android/IOS API
              window.webkit.messageHandlers.submit.postMessage(
                `{
                  "type":"COACH_CHAT",
                  "returnUrl":"/chat/${token}",
                  "deepLink":"nutu://social/chat/coach?generateResponse=true"
                }`,
              );
            }
          }, 2000);
        }
        if (jsonMessage.from !== CHATBOT_NAME) {
          jsonMessage.timestamp = jsonMessage.created_at;
          setNewMessages((prev) => prev.concat(jsonMessage));
        } else {
          const jsonMessageContent = jsonMessage.contents;
          const definedMessages = jsonMessage.contents.filter(
            (json) => json.message !== undefined || json.title !== undefined,
          );
          setMessagestoDisplay((prev) => prev + definedMessages.length);
          // Time between messages
          let delayTime = delayIncrement;
          // Will split contents into separate messages if message is from bot
          const result = [];
          jsonMessageContent.forEach(async (content, index) => {
            const newContentMessage = {
              ...jsonMessage,
              contents: [{ ...content, wait: delayTime }],
            };
            delayTime += delayIncrement;
            result.push(newContentMessage);
          });
          await setNewMessages((prev) => prev.concat(...result));
        }
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    window.__enterMobileMessage__ = enterMobileMessage;
  }, []);

  useEffect(() => {
    handleReceivedMessage(lastMessage);
  }, [lastMessage]);

  const generateDialogResponses = (payloads, buttons, wait = 0, userToSendTo) => {
    const result = [];
    buttons.forEach((button, index) => {
      if (button) {
        result.push(
          <DelayContainer
            wait={wait}
            functionAfterWait={() => {
              if (autoScrollBottom) {
                scrollToBottomOfChatBox(endMessageRef);
              }
              setLoading(false);
            }}
          >
            <MessageBubble
              messageText={button}
              backgroundColor="#5eb7c3"
              textColor="#FFFFFF"
              width="100%"
              minHeight={buttons.length === 1 ? '75px' : '61px'}
              isDialogResponse
              height={buttons.length === 1 ? '75px' : '100%'}
              onClick={async () => {
                setLoading(true);
                const message = {
                  from: username,
                  to: userToSendTo,
                  contents: [
                    {
                      type: 'text',
                      message: button,
                      timestamp: new Date().valueOf(),
                      payload: payloads[index],
                    },
                  ],
                };
                await sendJsonMessage(message);
                result.pop();
                setNewMessages([...newMessages, message]);
              }}
            />
          </DelayContainer>,
        );
      }
    });

    return (
      <div className={result.length > 1 ? 'dialog-responses' : 'dialog-response'}>{result}</div>
    );
  };

  const generateMessageTypes = (allMessages, isNewMessage = false) => {
    const result = [];
    allMessages.forEach((singleMessage, index) => {
      const { wait } = singleMessage;
      const messageFromUser = singleMessage.from === username;
      if (
        (singleMessage.type === MESSAGE__TYPE.TEXT ||
          singleMessage.type === MESSAGE__TYPE.REDIRECT) &&
        singleMessage.message !== undefined
      ) {
        result.push(
          <DelayContainer
            wait={wait}
            className={messageFromUser ? 'chatbox-messages right' : 'chatbox-messages'}
            functionAfterWait={() => {
              if (singleMessage.from === CHATBOT_NAME && isNewMessage) {
                setMessagestoDisplay((prev) => prev - 1);
              }
            }}
          >
            <MessageBubble
              maxWidth="70%"
              messageText={singleMessage.message}
              alignRight={messageFromUser}
              generatedByAi={singleMessage.from === 'AI'}
              displayQuestionButton={
                singleMessage.from !== 'B' &&
                !messageFromUser &&
                singleMessage.type !== MESSAGE__TYPE.DIALOG &&
                singleMessage.type !== MESSAGE__TYPE.REDIRECT &&
                // Determines if current message is the most recent message sent
                JSON.stringify(singleMessage) === JSON.stringify(previousRecentMessage)
              }
              backgroundColor={messageFromUser ? '#5EB7C3' : '#FFFFFF'}
              textColor={messageFromUser ? '#FFFFFF' : '#425563'}
              timestamp={singleMessage.timestamp}
              messageType={singleMessage.type}
              onDidNotAnswer={() => {
                sendJsonMessage({ to: AI_NAME, contents: [{ type: 'hidden', payload: '$0' }] });
              }}
            />
          </DelayContainer>,
        );
      } else if (singleMessage.type === MESSAGE__TYPE.DIALOG) {
        result.push(
          <DelayContainer
            wait={wait}
            className="chatbox-messages"
            functionAfterWait={() => {
              if (isNewMessage) {
                setMessagestoDisplay((prev) => prev - 1);
              }
            }}
          >
            <MessageBubble
              maxWidth="70%"
              messageText={singleMessage.title || singleMessage.message}
              timestamp={singleMessage.timestamp}
              alignRight={false}
              backgroundColor="#FFFFFF"
              textColor="#425563"
              generatedByAi={singleMessage.from === 'AI'}
              displayQuestionButton={
                singleMessage.from !== 'B' && singleMessage.type !== MESSAGE__TYPE.DIALOG
              }
            />
          </DelayContainer>,
        );

        if (index === allMessages.length - 1 && isNewMessage) {
          result.push(
            generateDialogResponses(
              singleMessage.payloads,
              singleMessage.buttons,
              wait,
              singleMessage.from,
            ),
          );
        }
      }
    });

    return result;
  };

  const generateMessages = (allMessages, isNewMessage = false) => {
    const convertedMessages = [];
    allMessages.forEach((message) => {
      if (message.contents) {
        convertedMessages.push(
          ...convertMessageContents(message.contents, message.from, message.to, message.created_at),
        );
      }
    });

    return generateMessageTypes(convertedMessages, isNewMessage);
  };
  useEffect(() => {
    const latestMessage = messages.length > 0 ? messages[messages.length - 1] : null;
    const mostRecentMessage =
      newMessages.length > 0 ? newMessages[newMessages.length - 1] : latestMessage;
    if (mostRecentMessage) {
      const convertedRecentMessage = convertMessageContents(
        mostRecentMessage.contents,
        mostRecentMessage.from,
        mostRecentMessage.to,
        mostRecentMessage.created_at,
      );
      setMostRecentMessage(convertedRecentMessage[convertedRecentMessage.length - 1]);
    }

    if (endMessageRef.current && messages.length > 0 && autoScrollBottom) {
      scrollToBottomOfChatBox(endMessageRef);
    }
  }, [messages, newMessages]);

  return (
    <Container>
      <ChatBoxContainer displayMobileChatbox={displayMobileChatbox}>
        <div className="message-container" onScroll={handleChatScroll}>
          {/* Generates previous messages before current session*/}
          {generateMessages(messages)}
          {/* Generates messages in current session*/}
          {generateMessages(newMessages, true)}
          {messagesToDisplay > 0 && (
            <div className="chatbox-messages">
              <MessageBubble alignRight={false} backgroundColor="#FFFFFF" width="70px">
                <div className="dot-flashing mobile-chat" />
              </MessageBubble>
            </div>
          )}
          <div ref={endMessageRef} />
        </div>
        <div
          className={
            displayMobileChatbox
              ? 'mobile-chatbox-input-container'
              : 'mobile-chatbox-input-container-hidden'
          }
        >
          <div className="mobile-chatbox-inputs">
            <div className="mobile-chatbox-inputs__textarea-wrapper">
              <ChatInput messageText={messageText} setMessageText={setMessageText} />
            </div>
            {/* The empty p tag and click function is needed for mobile functionality */}
            <div className="chatbox-input-icons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  enterMobileMessage(messageText);
                  setMessageText('');
                }}
                className="chatbox-send-button"
              >
                Send
              </button>
            </div>
          </div>
          {/* {WEB_SOCKET_CONNECTION_STATUS.CLOSED === connectionState && (
            <div className="mobile-connection-error-text">
              Connection Error: Sent messages may not be received
            </div>
          )} */}
        </div>
      </ChatBoxContainer>
    </Container>
  );
}

const ChatBoxContainer = styled.div`
  height: 100%;
  background-color: #edece8;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  display: flex;
  flex-direction: column;

  .dot-flashing.mobile-chat {
    margin: auto;
  }

  .mobile-connection-error-text {
    color: red;
    margin: 5px 0px 0px 19px;
    font-size: 12px;
  }

  .companion-chatbox-name {
    display: flex;
    padding: 15px;
    align-items: center;

    > div {
      font-size: 18px;
      font-weight: bold;
      color: #425563;
    }
  }

  .mobile-chatbox-close {
    color: #5eb7c3;
    margin-right: 4%;
  }

  .chatbox-messages {
    margin-bottom: 12px;
  }

  .chatbox-messages.right {
    display: flex;
    justify-content: flex-end;
  }

  .chatbox-header {
    padding-left: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .message-container {
    height: ${(props) => (props.displayMobileChatbox ? '95%' : '100%')};
    padding: 0px 20px 10px 20px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .mobile-chatbox-input-container {
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    background-color: #f7f8fc;
    max-height: 26vh;
    padding: 10px 0px;
    flex-grow: 1;
    display: flex;
  }

  .mobile-chatbox-input-container-hidden {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  .mobile-chatbox-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .mobile-chatbox-inputs__textarea-wrapper {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .chat-textbox {
    resize: none;
    border: none;
    width: 100%;
    height: 35px;
    border-radius: 15px;
    padding: 5px 10px;
    margin-left: 3%;
    max-height: 24vh;
    padding-right: 2rem;
    outline: none;
  }

  .chat-textbox::placeholder {
    color: #c8d5de;
  }

  .chatbox-input-icons {
    width: 30%;
    margin-left: 5px;
    display: flex;
    justify-content: space-evenly;
  }

  .chatbox-icons {
    font-size: 25px;
    line-height: '25px';
    color: #dcdcdc;
  }

  .chatbox-icons:hover {
    cursor: pointer;
    color: grey;
  }

  .chatbox-emoji-picker {
    position: absolute;
  }

  .chatbox-send-button {
    min-width: 80%;
    height: 35px;
    border-radius: 5px;
    color: white;
    border: 1px solid #5eb7c3;
    background-color: #5eb7c3;
    outline: none;
  }

  .dialog-responses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .dialog-response {
    margin: auto;
    width: 170px;
  }
`;

const Container = styled.div`
  height: 100%;
  .connection-text {
    font-size: 12px;
  }
`;
MobileChatBox.propTypes = {
  username: PropTypes.string,
  messages: PropTypes.arrayOf(MessagePropType),
  connectionState: PropTypes.number,
  sendJsonMessage: PropTypes.func,
  setMessageHistory: PropTypes.func,
  handleChatScroll: PropTypes.func.isRequired,
  displayMobileSendMessage: PropTypes.bool,
  autoScrollBottom: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  lastMessage: PropTypes.object,
  fetchPreviousMessages: PropTypes.func,
};

export default MobileChatBox;
